import Layout from "components/Layout/Layout";
import ContactMain from "components/Policy/ContactMain";
import React from "react";

const ContactUs = () => {
  return (
    <Layout title={"Contact Us"}>
      <ContactMain />
    </Layout>
  );
};

export default ContactUs;
