import Button2 from "components/Layout/Fields/Button2";
import Image from "components/Layout/Fields/Image";
import React from "react";

const MainPoster = ({ className ,  mainPoster = {}, }) => {
  const { title, image, subtitle } = mainPoster || "";

  return (
    <div className=" ">
      <div className={`w-full  ${className} relative bg-no-repeat bg-center `}>
        <Image className={""} src={image} />
        <div
          className="
  bg-black/20
         w-full
         absolute bottom-0  z-10  h-full px-5  md:px-20 py-7   flex items-center"
        >
          <div className=" flex flex-col gap-2 md:gap-5 w-full">
            <h1 className="   text-2xl md:text-4xl font-semibold text-white">
              {title}
            </h1>
            <p className="  text-white text-sm">{subtitle}</p>
            {/* <div className=" flex flex-col md:flex-row gap-3">
              <Button2 disabled={false} className={"  "}>
                JOIN OUR CLUB
              </Button2>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPoster;
