import React, { Fragment, useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation } from "swiper/modules";
import Currency from "react-currency-formatter";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import Image from "components/Layout/Fields/Image";
import { FiHeart } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import BeforeModal from "components/Wishlist/BeforeModal";
import { db } from "Firestore/Firebase";
import {
  collection,
  deleteDoc,
  doc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import toast from "react-hot-toast";
import { FaHeart } from "react-icons/fa6";

const Card = ({
  wishlist,
  item,
  addWishlist,
  openModal,
  user,
  removeWishlist,
}) => {
  const data = item;

  const wishCheck =
    Array.isArray(wishlist) && wishlist.some((item) => item.id === data.id);
  const Icon = wishCheck ? FaHeart : FiHeart;

  const navigate = useNavigate();
  const handleProductClick = (item) => {
    navigate(`/products/${item.category}/${item.id}`);
    window.location.reload();
  };

  return (
    <div className="relative group">
      <div
        className="   cursor-pointer flex flex-col gap-2   "
        // onClick={() => changeRequest(item)}
        onClick={() => handleProductClick(item)}
      >
        <div className="h-[450px] overflow-hidden rounded">
          <Image
            className={`  ${item?.image2 ? " md:group-hover:hidden" : ""} `}
            src={item.image}
            alt=""
          />
          {item?.image2 && (
            <Image
              className={`md:group-hover:flex   hidden `}
              src={item?.image2}
              alt=""
            />
          )}

          {/* <img
      className=" w-full object-cover  h-full"
      src={item.image}
      alt=""
    /> */}
        </div>
        <div>
          <div className="  flex px-2  justify-between">
            <div className=" flex flex-col gap-1">
              <h1 className=" text-white  font-medium text-sm">{item.name}</h1>
              <h1 className=" text-xs   text-secondary-300  font-light">
                {item.subtitle}
              </h1>
            </div>
            <h1 className="  text-primary-100 font-medium text-xs">
              <Currency quantity={item.price} currency="inr" />
            </h1>
          </div>
        </div>
      </div>

      <div className=" absolute z-20  right-2 top-2">
        <button
          // onClick={openModal}

          onClick={() =>
            user
              ? wishCheck
                ? removeWishlist(item.id)
                : addWishlist(item)
              : openModal(item)
          }
          className={`
            flex     rounded-full  overflow-hidden
            p-2    bg-backgound-300 text-secondary-200  `}
        >
          <Icon color="black" size={16} />
        </button>
      </div>
    </div>
  );
};

const ProductSwiper = ({
  data,
  title,
  openModal,
  user,
  wishlist,
  removeWishlist,
  addWishlist,
}) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const swiperRef = useRef(null);

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.navigation.init();
      swiperRef.current.navigation.update();
    }
  }, [swiperRef.current]);

  return (
    <Fragment>
      <div className=" flex  flex-col gap-5">
        <div className=" flex  flex-row justify-between">
          <h1 className=" text-white font-semibold text-xl  md:text-2xl uppercase">
            {title}
          </h1>

          {data.length > 3 && (
            <div className="flex-row  hidden md:flex gap-3">
              <button
                ref={prevRef}
                className="swiper-button-prev p-1 rounded-full bg-white hover:bg-gray-300 disabled:bg-gray-200 disabled:cursor-not-allowed"
              >
                <MdOutlineKeyboardArrowLeft size={25} />
              </button>
              <button
                ref={nextRef}
                className="swiper-button-next p-1 rounded-full cursor-pointer bg-white hover:bg-gray-300 disabled:bg-gray-200 disabled:cursor-not-allowed"
              >
                <MdKeyboardArrowRight size={25} />
              </button>
            </div>
          )}
        </div>

        <Swiper
          onInit={(swiper) => {
            swiperRef.current = swiper;
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
          }}
          slidesPerView={4}
          breakpoints={{
            200: { slidesPerView: 1.2 },
            640: { slidesPerView: 2 },
            768: { slidesPerView: 2 },
            1024: { slidesPerView: 3 },
            1280: { slidesPerView: 4 },
          }}
          modules={[Navigation]}
          navigation={{
            prevEl: prevRef.current,
            nextEl: nextRef.current,
          }}
          spaceBetween={10}
          className="mySwiper"
        >
          {data?.map((item, index) => (
            <SwiperSlide key={index}>
              <Card
                removeWishlist={removeWishlist}
                wishlist={wishlist}
                item={item}
                user={user}
                openModal={openModal}
                addWishlist={addWishlist}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Fragment>
  );
};

export default ProductSwiper;
