import { db } from "Firestore/Firebase";
import order from "assets/images/no-order.png";
import Image from "components/Layout/Fields/Image";
import { collection, onSnapshot, query } from "firebase/firestore";

import React, { Fragment, useEffect, useState } from "react";
import Currency from "react-currency-formatter";
import { FiHeart } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

const Card = ({ item, user }) => {
  const navigation = useNavigate();

  const [products, setProducts] = useState(null);

  useEffect(() => {
    try {
      const q = query(
        collection(db, "users", user?.uid, "orders", item?.id, "products")
      );

      onSnapshot(q, (querySnapshot) => {
        const cities = [];
        querySnapshot.forEach((doc) => {
          cities.push({ id: doc.id, ...doc.data() });
        });

        setProducts(cities);
      });
    } catch {
      console.log("Error fetching address");
    } finally {
    }
  }, [user]);

  const formatTimestamp = (timestamp) => {
    const milliseconds =
      timestamp?.seconds * 1000 + timestamp?.nanoseconds / 1000000;
    const date = new Date(milliseconds);
    const options = { year: "numeric", month: "short", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  return (
    <Fragment>
      <div className=" border  border-secondary-200 p-4   flex flex-col gap-5   pb-4 relative  ">
        <div className=" grid gap-5  grid-cols-2 sm:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-4">
          {products?.map((data, index) => (
            <div key={index} className="  flex flex-col gap-2 ">
              <div
                onClick={() =>
                  navigation("/order/detail", { state: { id: item.id } })
                }
                className=" cursor-pointer h-[200px] md:h-[250px] overflow-hidden rounded"
              >
                <Image className={""} src={data.image} alt="" />
              </div>

              <div className="  flex   gap-1 flex-col">
                <div className=" flex flex-col gap-1">
                  <div className=" flex   flex-wrap  gap-1 flex-row justify-between">
                    <h1 className=" text-white  font-medium text-xs">
                      {data.name}
                    </h1>{" "}
                    {/* <h1 className="  text-primary-100 font-medium text-xs">
                      <Currency quantity={data.price * data.quantity} currency="inr" />
                    </h1> */}
                  </div>
                </div>

                <div className=" flex flex-row justify-between">
                  {data.size !== " " && (
                    <h1 className="  capitalize text-secondary-300 text-xs">
                      Size : {data.size}
                    </h1>
                  )}
                  <h1 className="  capitalize text-secondary-300 text-xs">
                    Quantity : {data.quantity}
                  </h1>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div>
          <div className="  flex   gap-1 flex-col">
            <div className=" flex flex-col gap-1">
              <div className=" flex   gap-1  flex-col sm:flex-row justify-between flex-wrap ">
                <h1
                  onClick={() => navigation("/order/detail")}
                  className=" text-white cursor-pointer  font-medium text-sm underline"
                >
                  {item.orderid}
                </h1>
                <h1 className=" text-xs  text-white  ">
                  Date ordered : {formatTimestamp(item.date)}
                </h1>
              </div>
              <div className=" flex   gap-1 flex-col sm:flex-row justify-between">
                <h1 className=" text-white  font-medium text-xs">
                  Items : {item.quantity}
                </h1>{" "}
                <h1 className="  text-primary-100 font-medium text-xs">
                  <span className=" text-white">Total</span> :{" "}
                  <Currency quantity={item.total} currency="inr" />
                </h1>
              </div>
            </div>

            <h1 className="  capitalize text-secondary-300 text-xs">
              <span className="  text-white">Order status :</span> {""}{" "}
              {item.status}
            </h1>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const Empty = () => {
  return (
    <div className=" flex justify-center  py-10">
      <div className=" flex flex-col  items-center">
        <div className="   w-40">
          <Image src={order} alt="" />
        </div>

        <div className=" text-center">
          <h1 className=" tracking-wide  text-base  font-semibold text-white uppercase">
            NO ORDERS
          </h1>
          <p className="      font-extralight  text-sm text-secondary-300">
            You’ve made no orders
          </p>
        </div>
      </div>
    </div>
  );
};

const Orders = ({ data, user }) => {
  return (
    <div className=" flex flex-col gap-10">
      <div>
        <h1 className="  tracking-wide  text-xl  font-semibold text-white uppercase">
          YOUR ORDERS
        </h1>
      </div>
      {data?.length > 0 ? (
        <div className=" flex  flex-col gap-4  ">
          {data?.map((item, index) => (
            <Card user={user} key={index} item={item} />
          ))}
        </div>
      ) : (
        <Empty />
      )}
    </div>
  );
};

export default Orders;
