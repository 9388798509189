import Image from "components/Layout/Fields/Image";
import ModalSection from "components/Layout/ModalSection";
import React, { Fragment, useState } from "react";
import Currency from "react-currency-formatter";
import { HiMiniXMark } from "react-icons/hi2";
import { TbRuler2, TbTruckDelivery } from "react-icons/tb";
import Size from "assets/images/sizechart.png";
import Button2 from "components/Layout/Fields/Button2";
import Button3 from "components/Layout/Fields/Button3";
import { FiHeart } from "react-icons/fi";
import { RiLoopRightFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { collection, doc, serverTimestamp, setDoc } from "firebase/firestore";
import { db } from "Firestore/Firebase";
import toast from "react-hot-toast";
import { FaHeart } from "react-icons/fa6";
import { useDispatch } from "react-redux";
import { addToBasket } from "ReduxSlice/BasketSlice";
import InputText from "components/Layout/Fields/InputText";

import Button4 from "components/Layout/Fields/Button4";
import axios from "axios";
import { BASE_URL } from "../../config";
import { format, parseISO } from "date-fns";

const ProductDetail = ({
  user,
  wishlist,
  removeWishlist,
  openModal,
  colors,
  size,
  product = {},
}) => {
  const {
    name,
    id,
    subtitle,
    sizechart,
    price,
    description,
    available,
    stock,
    color,
    category,
    image,
  } = product || "";

  // console.log(user)

  const [sizeSelect, setSizeSelect] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openSizeModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const closeSizeModal = () => {
    setIsModalOpen(false);
  };
  const navigation = useNavigate();

  const handleProductClick = (item) => {
    navigation(`/products/${item.category}/${item.id}`);
    window.location.reload();
  };

  const wishCheck =
    Array.isArray(wishlist) && wishlist.some((item) => item.id === id);

  const addWishlist = async () => {
    try {
      const docRef = doc(collection(db, "users", user?.uid, "wishlist"), id);

      await setDoc(docRef, {
        id: id,
        name: name,
        createdAt: serverTimestamp(),
      });

      toast.success("Added Successfully", {
        style: {
          background: "rgba(25,25,25)",
          color: "white",
          fontSize: "13px",
          textTransform: "uppercase",
        },
      });
    } catch (error) {
      console.error("Error adding document: ", error);
      toast.error(error.message, {
        style: {
          background: "rgba(25,25,25)",
          color: "white",
          fontSize: "13px",
          textTransform: "uppercase",
        },
      });
    }
  };
  const Icon = wishCheck ? FaHeart : FiHeart;

  const dispatch = useDispatch();

  const addToCart = () => {
    dispatch(
      addToBasket({
        name,
        id,
        subtitle,
        price,
        color,
        size: sizeSelect,
        category,
        image,
        // createdate: new Date().toLocaleDateString("en-CA"),
        // createdate:"2024-07-08",

        quantity: 1,
      })
    );
    navigation("/cart");
    toast.success("Added to Cart", {
      style: {
        background: "rgba(25,25,25)",
        color: "white",
        fontSize: "13px",
        textTransform: "uppercase",
      },
    });
  };

  const [pincode, setPincode] = useState(null);
  const [load, setLoad] = useState(false);
  const [date, setDate] = useState(null);
  const [errors, setErrors] = useState({});
  const formattedDate = date && format(parseISO(date), "EEE, MMM d");
  const apiEndpoint = `${BASE_URL}/order/check-availaibilty?deliveryPincode=${pincode}&quantity=1`;

  const handlePostRequest = async (e) => {
    e.preventDefault();

    setErrors({});
    if (pincode.length === 6) {
      try {
        setLoad(true);
        const response = await axios.get(apiEndpoint);
        setDate(response.data.data.data.deliveryDate);
      } catch (err) {
        setErrors({
          pincode: "Sorry! Your PIN code is not serviceable.",
        });
        setDate("");
        toast.error("Service is not available for this PIN code.", {
          style: {
            background: "rgba(25,25,25)",
            color: "white",
            fontSize: "13px",
            textTransform: "uppercase",
          },
        });
      } finally {
        setLoad(false);
      }
    } else {
      setErrors({
        pincode: "Please enter a valid pincode within 6 digits",
      });
      setDate("");
      toast.error("Please enter a valid 6-digit PIN code.", {
        style: {
          background: "rgba(25,25,25)",
          color: "white",
          fontSize: "13px",
          textTransform: "uppercase",
        },
      });
    }
  };
  const descriptionLines =
    description &&
    description
      ?.trim()
      ?.split("\n")
      ?.map((line) => line.trim());

  return (
    <Fragment>
      <div className=" flex  flex-col gap-4">
        {/* Price */}
        <div className=" flex flex-col gap-2">
          <div>
            <h1 className=" text-2xl font-semibold text-white tracking-wider">
              {name}
            </h1>
            <h1 className=" text-secondary-300 font-light text-sm">
              {subtitle} <span className=" uppercase">({color})</span>
            </h1>
          </div>
          <div>
            <h1 className="  text-primary-100 font-medium  text-base">
              <Currency quantity={price} currency="inr" />
            </h1>
            {/* <h1 className="  text-secondary-300 font-medium  text-xs line-through">
              <Currency quantity={1200} currency="inr" />
            </h1> */}
          </div>
          <h1 className=" text-secondary-300  font-light  text-xs">
            Prices include GST
          </h1>
          <h1 className="  text-white text-xs">
            {stock ? "IN STOCK" : "OUT OF STOCK"}
          </h1>
        </div>
        {/* Color */}
        {colors.length > 0 && (
          <div className=" flex flex-col  gap-2">
            <div>
              <h1 className=" text-base font-semibold text-white  tracking-wider">
                Colors
              </h1>
            </div>
            <div>
              <div className=" flex flex-row gap-2">
                {colors.map((item, index) => (
                  <div
                    onClick={() => handleProductClick(item)}
                    key={index}
                    className="  w-14 cursor-pointer"
                  >
                    <Image src={item.image} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {/* Size */}

        {size.length > 0 && (
          <div className="border-t  py-5  mt-2 border-border-100">
            <div className=" flex flex-col gap-5 ">
              <div className=" flex flex-col gap-2">
                <h1 className=" text-base font-semibold text-white  tracking-wider">
                  Size
                </h1>
                <div className="  flex flex-row justify-between">
                  {sizeSelect ? (
                    <h1 className="     text-secondary-300 uppercase text-xs">
                      Selected Size : {sizeSelect}
                    </h1>
                  ) : (
                    <h1 className="    text-secondary-300 uppercase text-xs">
                      Select Size
                    </h1>
                  )}
                </div>
              </div>

              <div className=" flex flex-wrap gap-3">
                {size.map((item, index) => (
                  <button
                    disabled={item.stock ? false : true}
                    onClick={() =>
                      setSizeSelect((pre) =>
                        pre === item.size ? null : item.size
                      )
                    }
                    key={index}
                    className={` ${
                      sizeSelect === item.size
                        ? " bg-white text-secondary-100 border-transparent"
                        : "text-white  border-border-100"
                    }
                      ${
                        item.stock === true
                          ? "hover:border-white"
                          : "bg-secondary-200  hover:border-transparent cursor-not-allowed"
                      }
                      border lg:p-2 p-2 px-4 lg:px-4`}
                  >
                    <h1 className=" text-sm font-light  uppercase">
                      {item.size}
                    </h1>
                  </button>
                ))}
              </div>
              <div
                onClick={openSizeModal}
                className=" flex gap-2  text-white hover:text-secondary-300 items-center cursor-pointer"
              >
                <h1 className=" text-xs underline tracking-wider  ">
                  SIZE GUIDE
                </h1>
                <TbRuler2 size={15} />
              </div>
            </div>
          </div>
        )}

        {/* Actions */}
        <div className="border-t  py-9  flex flex-col gap-6  border-border-100">
          <div className=" flex flex-col gap-3">
            {stock ? (
              <Fragment>
                {size.length > 0 ? (
                  <Button2
                    onClick={() => {
                      if (sizeSelect) {
                        addToCart();
                      } else {
                        toast.error("Select Size", {
                          style: {
                            background: "rgba(25,25,25)",
                            color: "white",
                            fontSize: "13px",
                            textTransform: "uppercase",
                          },
                        });
                      }
                    }}
                    disabled={false}
                    className={
                      "  whitespace-pre md:px-5  py-4 lg:px-10  text-xs xl:text-base uppercase"
                    }
                  >
                    Add to cart
                  </Button2>
                ) : (
                  <Button2
                    onClick={addToCart}
                    disabled={false}
                    className={
                      "  whitespace-pre md:px-5  py-4 lg:px-10  text-xs xl:text-base uppercase"
                    }
                  >
                    Add to cart
                  </Button2>
                )}
              </Fragment>
            ) : (
              <Button2
                onClick={() => {
                  toast.error("out of stock", {
                    style: {
                      background: "rgba(25,25,25)",
                      color: "white",
                      fontSize: "13px",
                      textTransform: "uppercase",
                    },
                  });
                }}
                disabled={false}
                className={
                  "  whitespace-pre md:px-5  py-4 lg:px-10  text-xs xl:text-base uppercase"
                }
              >
                Out Of Stock
              </Button2>
            )}

            <Button3
              onClick={() =>
                user
                  ? wishCheck
                    ? removeWishlist(id)
                    : addWishlist()
                  : openModal(product)
              }
              disabled={false}
              className={" border py-4"}
            >
              <Icon size={16} />
              {wishCheck ? "Remove from Wishlist" : "Add to Wishlist"}
            </Button3>
          </div>
          {/* <div className=" flex items-center gap-1">
            <RiLoopRightFill size={18} className="  text-secondary-300" />
            <h1 className=" text-secondary-300 text-sm font-medium">
              Free returns on all qualifying orders.
            </h1>
          </div> */}
        </div>

        {/* Description */}

        <div className="border-t  pt-8  flex flex-col gap-5  border-border-100">
          <div className=" flex flex-col space-y-6">
            <h1 className=" text-white text-sm font-medium">
              Please enter PIN code to check delivery time
            </h1>
            <div className=" flex-col flex  gap-5">
              <form
                onSubmit={handlePostRequest}
                className=" flex space-x-2 items-center"
              >
                <InputText
                  type="text" // Change to text to enforce maxLength
                  maxLength={6}
                  required={true}
                  placeholder={"PIN Code"}
                  value={pincode}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value) && value.length <= 6) {
                      setPincode(value);
                    }
                  }}
                />
                <Button4 disabled={load} className={"  uppercase py-3  "}>
                  Apply
                </Button4>
              </form>

              {!errors.pincode && date && (
                <div className=" flex flex-row gap-1">
                  <TbTruckDelivery size={20} color="#0085ca" />
                  <h1 className=" text-primary-100 text-sm    uppercase font-medium">
                    Expected by {formattedDate}
                  </h1>
                </div>
              )}

              {errors && (
                <div className=" flex flex-row gap-1">
                  <h1 className=" text-red-800 text-sm    uppercase font-medium">
                    {errors.pincode}
                  </h1>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="border-t  py-8  flex flex-col gap-5  border-border-100">
          <div className=" flex flex-col gap-5 ">
            <div className=" flex flex-col gap-2">
              <h1 className=" text-base font-semibold text-white  tracking-wider">
                Description
              </h1>
              <div className="  flex flex-row justify-between">
                <p
                  className="text-secondary-300 font-light text-sm"
                  style={{ whiteSpace: "pre-line" }}
                >
                  {description}
                </p>
              </div>
            </div>
          </div>
          <div className=" flex flex-col gap-5 ">
            <div className=" flex flex-col gap-2">
              <h1 className=" text-base font-semibold text-white  tracking-wider">
                Shipping and Returns
              </h1>
              <div className="  flex flex-row justify-between">
                <p className=" text-secondary-300 font-light text-sm">
                  Free Delivery for all qualifying orders.
                  {/*            
                  Visit our{" "}
                  <span className=" underline cursor-pointer">
                  Contact us
                  </span>{" "} */}
                  {/* for more information. */} {""}
                  For any queries, please contact Customer Service at +91
                 70033 51046 or via{" "}
                  <span className=" underline cursor-pointer">
                  ONEPERCENTCLUB@GMAIL.COM
                  </span>{" "}
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalSection isOpen={isModalOpen} onClose={closeSizeModal} className="">
        <Fragment>
          <div className="  flex flex-col gap-4 ">
            <div className=" flex justify-between items-center">
              <h1 className=" text-secondary-100 text-sm  tracking-wider font-medium">
                SIZE GUIDE
              </h1>
              <button onClick={closeSizeModal}>
                <HiMiniXMark size={25} />
              </button>
            </div>
            <div className=" border-y py-5  border-secondary-300 ">
              <h1 className=" text-center font-semibold text-xl text-secondary-100 uppercase tracking-wider">
                International Size Chart
              </h1>
            </div>
            <div className=" flex flex-col gap-4">
              <h1 className="tracking-wider text-sm font-semibold text-secondary-100 text-center">
               BODY MEASUREMENTS (Inches)
              </h1>
              <div className=" flex justify-center">
                <div className="  h-[400px]  w-[400px]">
                  <Image src={sizechart} />
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      </ModalSection>
    </Fragment>
  );
};

export default ProductDetail;
