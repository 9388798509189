import { selectBasketTotal } from "ReduxSlice/BasketSlice";
import Button2 from "components/Layout/Fields/Button2";
import Button4 from "components/Layout/Fields/Button4";
import InputText from "components/Layout/Fields/InputText";
import React, { Fragment, useEffect, useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { RiLoopRightFill } from "react-icons/ri";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Currency from "react-currency-formatter";
import { doc, getDoc } from "firebase/firestore";
import toast from "react-hot-toast";
import { db } from "Firestore/Firebase";

const Summery = ({ user }) => {
  const [show, setShow] = useState(false);

  const openShow = () => {
    setShow(!show);
  };
  const closeShow = () => {
    setShow(false);
  };

  const Icon = show ? IoIosArrowUp : IoIosArrowDown;
  const naviagtion = useNavigate();

  const basketTotal = useSelector(selectBasketTotal);
  const [subTotal, setSubTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [inputcoupon, setinputcoupon] = useState("");
  const [coupon, setCoupon] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const discountPercent =
    coupon !== null ? (parseInt(coupon?.discount) / 100) * basketTotal : null;

  useEffect(() => {
    setSubTotal(basketTotal);
    setTotalAmount(basketTotal - discountPercent);
  }, [basketTotal, coupon]);

  const [coupontry, setCoupontry] = useState(false);

  const onClickCoupon = (e) => {
    e.preventDefault();

    setCoupontry(true);
    const couponCode = inputcoupon.toLowerCase();

    getDoc(doc(db, "coupons", couponCode)).then((docSnap) => {
      if (docSnap.exists()) {
        if (docSnap.data().available === false) {
          toast.error("Discount code is expired", {
            style: {
              background: "rgba(25,25,25)",
              color: "white",
              fontSize: "13px",
              textTransform: "uppercase",
            },
          });
          setCoupontry(false);
          return; // Exit the function
        }

        const discountPercentage = parseInt(docSnap.data()?.discount);
        const discountAmount = (discountPercentage / 100) * basketTotal;
        const newTotalAmount = basketTotal - discountAmount;

        setTotalAmount(newTotalAmount);
        setDiscount(discountAmount);
        setCoupon(docSnap.data());
        setShow(false);
        setTimeout(() => {
          setCoupontry(false);
        }, 2000);
        toast.success("Coupon added successfully", {
          style: {
            background: "rgba(25,25,25)",
            color: "white",
            fontSize: "13px",
            textTransform: "uppercase",
          },
        });
      } else {
        setCoupontry(false);
        setTotalAmount(totalAmount);
        toast.error("Discount code is not valid", {
          style: {
            background: "rgba(25,25,25)",
            color: "white",
            fontSize: "13px",
            textTransform: "uppercase",
          },
        });
      }
      setinputcoupon("");
    });
  };
  const removeCoupon = () => {
    setCoupon(null);
    setDiscount(0);
  };

  // console.log(subTotal);
  // console.log(discountPercent,'discountPercent');
  // console.log(discount,'discount');
  // console.log(totalAmount);

  return (
    <div className=" flex flex-col gap-5">
      <div className=" flex items-center justify-center gap-2 border  border-secondary-300 p-3 rounded">
        {/* <RiLoopRightFill size={18} className="  text-secondary-300" /> */}
        <h1 className=" text-secondary-300 text-xs  md:text-sm font-medium uppercase tracking-wider">
          Free delivery on all qualifying orders.
        </h1>
      </div>
      <div className=" flex flex-col  gap-4">
        {coupon === null && (
          <Fragment>
            <div
              onClick={openShow}
              className=" bg-secondary-300 p-4 flex justify-between items-center cursor-pointer"
            >
              <h1 className=" uppercase text-secondary-100  text-base">
                Apply a coupon code
              </h1>
              <Icon size={22} />
            </div>
            {show && (
              <form
                onSubmit={onClickCoupon}
                className=" flex gap-4 items-center"
              >
                <InputText
                  required={true}
                  value={inputcoupon}
                  onChange={(e) => setinputcoupon(e.target.value)}
                  type={"text"}
                  className=""
                  placeholder={"Enter a coupon code"}
                  // error={errors.name}
                />
                <Button4 disabled={coupontry} className={"  uppercase py-3  "}>
                  Apply
                </Button4>
              </form>
            )}
          </Fragment>
        )}
        <div className=" border-y py-6  my-3 border-secondary-200 flex flex-col gap-4">
          <div className=" flex justify-between items-center">
            <h1 className="   text-xs font-medium text-secondary-300 tracking-wider">
              SUBTOTAL
            </h1>
            <h1 className=" text-xs font-medium   text-white tracking-wider">
              <Currency quantity={subTotal} currency="INR" />
            </h1>
          </div>
          {coupon !== null && (
            <div className=" flex justify-between items-center">
              <div>
                <h1 className="   text-xs font-medium text-secondary-300 tracking-wider">
                  COUPON DISCOUNT {coupon.discount}%{" "}
                  <span className="  text-primary-100">({coupon.name})</span>
                  <br />
                  <span
                    onClick={removeCoupon}
                    className=" underline cursor-pointer"
                  >
                    Remove
                  </span>
                </h1>
              </div>
              <h1 className=" text-xs font-medium   text-white tracking-wider">
                - <Currency quantity={discountPercent} currency="INR" />
              </h1>
            </div>
          )}

          {/* <div className=" flex justify-between items-center">
            <h1 className="   text-xs font-medium text-secondary-300 tracking-wider">
              SHIPPING COSTS
            </h1>
            <h1 className=" text-xs font-medium   text-white tracking-wider">
              ₹ 100.00
            </h1>
          </div> */}
        </div>
        <div className=" flex justify-between items-center gap-4  flex-wrap">
          <h1 className="   text-base font-medium text-secondary-300 tracking-wider">
            GRAND TOTAL{" "}
            <span className=" text-xs  text-primary-100">
              {" "}
              PRICES INCLUDE GST
            </span>
          </h1>
          <h1 className=" text-base font-medium   text-white tracking-wider">
            <Currency quantity={totalAmount} currency="INR" />
          </h1>
        </div>

        <Button4
          onClick={() => {
            if (user) {
              naviagtion("/checkout", {
                state: {
                  total: totalAmount,
                  discount: discount,
                  coupon: coupon,
                },
              });
            } else {
              naviagtion("/login", {
                state: { cart: true },
              });
            }
          }}
          className={"uppercase text-base py-4"}
        >
          Checkout
        </Button4>
      </div>
      <p className=" text-xs text-secondary-300">
        By continuing, I confirm that I have read and accept the{" "}
        <Link
          to={"/terms-and-conditions"}
          className=" underline cursor-pointer"
        >
          Terms and Conditions
        </Link>{" "}
        and the{" "}
        <Link to={"/privacy-policy"} className=" underline cursor-pointer">
          Privacy Policy
        </Link>
        .
      </p>
    </div>
  );
};

export default Summery;
