import { auth, db } from "Firestore/Firebase";
import AddressMain from "components/Address/AddressMain";
import Layout from "components/Layout/Layout";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";

const Address = () => {
  const navigation = useNavigate();
  const [user] = useAuthState(auth);
  const [userDetails, setUserDetails] = useState(null);
  const [addressList, setAddressList] = useState(null);
  const [loading, setLoading] = useState(true);
  const [mainAddress, setMainAddress] = useState(null);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const docRef = doc(db, "users", user?.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setUserDetails(docSnap.data());
          setLoading(false);
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };

    if (user) {
      fetchData();
    } else {
      // setLoading(false);
    }
  }, [user]);

  // MainAddress
  useEffect(() => {
    const fetchData = async () => {
      if (userDetails && userDetails.mainaddress) {
        const docRef = doc(
          db,
          "users",
          user?.uid,
          "address",
          userDetails?.mainaddress
        );
  
        const unsubscribe = onSnapshot(docRef, (docSnap) => {
          if (docSnap.exists()) {
            setMainAddress(docSnap.data());
            setLoading(false);
          } else {
            console.log("No such document!");
          }
        }, (error) => {
          console.error("Error fetching document:", error);
        });
  
        return () => unsubscribe(); // Cleanup the listener on component unmount
      } else {
        console.log("User details or main address is missing");
        setLoading(false);
      }
    };
  
    if (userDetails?.mainaddress) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, [user, userDetails]);
  

  // console.log(mainAddress)
  // console.log(userDetails)


  // Address
  useEffect(() => {
    try {
      const q = query(
        collection(db, "users", user?.uid, "address"),
        orderBy("createdAt", "desc")
      );

      onSnapshot(q, (querySnapshot) => {
        const cities = [];
        querySnapshot.forEach((doc) => {
          cities.push({ id: doc.id, ...doc.data() });
        });
        setAddressList(cities);
        
    
      });
    } catch {
      console.log("Error fetching address");
    }
  }, [user, userDetails]);

  return (
    <Layout loading={loading} title={"Addresses"}>
      <AddressMain
        mainAddress={mainAddress}
        addressList={addressList}
        user={user}
        userDetails={userDetails}
      />
    </Layout>
  );
};

export default Address;
