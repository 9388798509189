import React, { Fragment } from "react";
import Button from "components/Layout/Fields/Button";
import Image from "components/Layout/Fields/Image";
import ModalSection from "components/Layout/ModalSection";
import { FiHeart } from "react-icons/fi";
import { HiMiniXMark } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import InputText from "components/Layout/Fields/InputText";

const ForgotModal = ({
  isModalOpen,
  closeModal,
  email,
  setEmail,
  handleReset,
  loading,
  errors,
}) => {
  const navigate = useNavigate();
  return (
    <ModalSection
      isOpen={isModalOpen}
      onClose={closeModal}
      padding={"p-0"}
      className="  w-[400px] md:min-w-[500px]   "
    >
      <Fragment>
        <div className=" p-5 flex flex-col gap-4">
          <div className=" relative flex  justify-between  items-center">
            <div className=" flex gap-2 items-center  n">
              <h1 className=" font-semibold text-lg ">RESET YOUR PASSWORD</h1>
            </div>
            <div className="  ">
              <button onClick={closeModal}>
                <HiMiniXMark size={25} />
              </button>
            </div>
          </div>

          <form onSubmit={handleReset} className="  flex  flex-col gap-4">
            <div className="   col-span-2">
              <InputText
                label={"EMAIL ADDRESS"}
                required={true}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type={"email"}
                color=" text-secondary-100"
                placeholder={"Email Address"}
                error={errors.forgotemail}
              
              />
            </div>

            <div className="  ">
              <Button
                required
                disabled={loading}
                className={
                  " text-lg py-3 md:py-3 uppercase   hover:bg-secondary-300"
                }
              >
                RESET PASSWORD
              </Button>
            </div>
          </form>
        </div>
      </Fragment>
    </ModalSection>
  );
};

export default ForgotModal;
