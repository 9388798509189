import Image from "components/Layout/Fields/Image";
import Select from "components/Layout/Fields/Select";
import React, { useState, useEffect } from "react";
import Currency from "react-currency-formatter";
import toast from "react-hot-toast";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { updateItemQuantity, removeFromBasket } from "ReduxSlice/BasketSlice";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { db } from "Firestore/Firebase";
import { doc, getDoc } from "firebase/firestore";
const ProductsCard = ({ item, index }) => {
  const [selected, setSelected] = useState(item?.quantity);
  const dispatch = useDispatch();
  const naviagtion = useNavigate();

  // const currentDate = new Date().toLocaleDateString("en-CA");

  // const removeAuto = currentDate > item.createdate ? true : false;

  const availableOptions = [
    { id: 1, value: "1", label: "1" },
    { id: 2, value: "2", label: "2" },
    { id: 3, value: "3", label: "3" },
    { id: 4, value: "4", label: "4" },
    { id: 5, value: "5", label: "5" },
    { id: 6, value: "6", label: "6" },
  ];

  useEffect(() => {
    handleQuantityChange(item.id, item.size, selected);
  }, [selected]);

  const handleQuantityChange = (id, size, quantity) => {
    dispatch(updateItemQuantity({ id, size, quantity }));
  };
  const deleteItem = () => {
    dispatch(removeFromBasket({ index }));
    toast.success("Remove Successfully", {
      style: {
        background: "rgba(25,25,25)",
        color: "white",
        fontSize: "13px",
        textTransform: "uppercase",
      },
    });
  };

  const handleProductClick = (item) => {
    naviagtion(`/products/${item.category}/${item.id}`);
  };

  const [product, setProduct] = useState(null);
  console.log(product)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const docRef = doc(db, "products", item.category, "products", item.id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setProduct(docSnap.data());
          // setLoading(false);
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };

    fetchData();
  }, [item]);

  useEffect(() => {
    if (product) {
      if (!product?.available || item?.price !== product?.price || !product?.stock) {
        deleteItem();

        toast.success("Out of stock", {
          style: {
            background: "rgba(25,25,25)",
            color: "white",
            fontSize: "13px",
            textTransform: "uppercase",
          },
        });

      }
    }
  }, [ product]);

  return (
    <div className="border border-secondary-200 p-5 flex xl:gap-0 gap-5 flex-col md:flex-row justify-between">
      <div className="flex justify-between md:justify-start gap-5">
        <div
          onClick={() => handleProductClick(item)}
          className=" cursor-pointer  w-[150px] lg:w-[100px] xl:w-[150px] h-40"
        >
          <Image src={item.image} />
        </div>
        <div className="md:w-auto w-[calc(100%-170px)] flex flex-col gap-3">
          <div>
            <h1
              onClick={() => handleProductClick(item)}
              className="text-white tracking-wider text-sm cursor-pointer "
            >
              {item.name}
            </h1>
            <h1 className="text-xs text-secondary-300">{item?.subtitle}</h1>
          </div>

          <div>
            {item?.color && (
              <h1 className="text-white tracking-wider font-extralight text-sm">
                Color:{" "}
                <span className="text-secondary-300 font-light capitalize">
                  {item?.color}
                </span>
              </h1>
            )}

            {item?.size && (
              <h1 className="text-white tracking-wider font-extralight text-sm">
                Size:{" "}
                <span className="text-secondary-300 uppercase font-light">
                  {item?.size}
                </span>
              </h1>
            )}
          </div>
          <div className="w-32">
            <Select
              label=""
              id="qty"
              onChange={(e) => setSelected(parseInt(e.target.value))}
              value={selected.toString()}
              options={availableOptions}
              className=""
            />
          </div>
        </div>
      </div>

      <div className="flex justify-between md:justify-start md:flex-col gap-3">
        <div>
          <h1 className="text-primary-100 font-medium text-sm whitespace-pre">
            <Currency quantity={item.price * item.quantity} currency="INR" />
          </h1>
        </div>
        <div className="flex justify-end">
          <button
            onClick={deleteItem}
            className="hover:bg-gray-800 p-2 rounded-full"
          >
            <RiDeleteBin6Line size={20} className="text-white" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductsCard;
