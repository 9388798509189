import React from "react";
import { HiMiniXMark } from "react-icons/hi2";
import { IoSearch } from "react-icons/io5";

const SearchField = ({ value,  placeholder, setSearch, ...props }) => {
  return (
    <div className=" relative flex items-center">
      <input
        {...props}
        value={value}
        maxLength={22}
        style={{ fontWeight: 400 }}
        placeholder={placeholder}
        className=" w-full text-gray p-3   text-secondary-100 text-sm  border rounded outline-none
        
        
        border-secondary-200   
              border-neutral-80 focus-within:border-neutral-100   ring-gray-500 focus-within:ring
        
        focus:border-secondary-100 "
        type="text"
      />
{value.length > 0 &&

      <button
        onClick={() => setSearch('')}
        className={` right-5  hover:bg-gray-300 p-2 rounded-full absolute`}
      >
        <HiMiniXMark size={20} className=" text-secondary-100 " />
      </button>
}
    </div>
  );
};

export default SearchField;
