import React, { Fragment, useState } from "react";
import MainPoster from "../MainPoster";
import ProductsSection from "../ProductsSection";
import ProductPoster from "../ProductPoster";

const MenMain = ({ data, mainPoster, poster }) => {
  const img1 =
    "https://row.gymshark.com/_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2Fwl6q2in9o7k3%2F2TQfEZ70DeAGCgW2AmrjSA%2F9bbd8c9c65f5a95b089e4102f286be0e%2FProduct_Push_Ecom__Collection_Page_Desktop_Mens.jpg&w=1664&q=85";
  const img2 =
    "https://row.gymshark.com/_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2Fwl6q2in9o7k3%2F3lQCAFxb7haULVzCw234Bi%2F5726ea7355e0f097da6e5d2c2137c2ee%2FHeadless_Desktop_-_3.jpg&w=3840&q=85";

  const button1 = {
    title: "Shop Lifting",
    path: "",
  };
  const button2 = {
    title: "Find out more",
    path: "",
  };

  return (
    <Fragment>
      <div className="bg-secondary-100  flex  flex-col gap-10 pb-20 ">
        {mainPoster && (
          <MainPoster
            className={`h-[200px] md:h-[500px]`}
            mainPoster={mainPoster}
          />
        )}

        <div className=" md:pt-10">
          <div className=" container   px-5 lg:px-16 flex flex-col gap-10 ">
            <ProductsSection
              data={data}
              title={"NEW MONTH, NEW DROPS"}
              title2={"MENS"}
            />
          </div>
        </div>

        {poster && (
          <ProductPoster
            className={`h-[400px] md:h-[600px]`}
            poster={poster}
            button1={button1}
            button2={button2}
          />
        )}
      </div>
    </Fragment>
  );
};

export default MenMain;
