import CardSwiper from "components/Cards/CardSwiper";
import Button2 from "components/Layout/Fields/Button2";
import React, { Fragment } from "react";

const Section3 = () => {
  const data = [
    {
      id: 1,
      image:
        "https://cdn.shopify.com/s/files/1/1367/5207/files/BlurSeamlessLowBraletteGSIrisBlue_GSPowderedLilacB9A2F-UCVV_640x.jpg?v=1717571493",
      title: "Arrival 5 Shorts ",
      title2: "Light Support",
      price: 8295,
    },
    {
      id: 2,
      image:
        "https://cdn.shopify.com/s/files/1/1367/5207/files/MeshTankGSBlackA6A2M-BB2J8287_1664x.jpg?v=1716981418",
      title: "MESH TANK ",
      title2: "Regular Fit",
      price: 5295,
    },
    {
      id: 3,
      image:
        "https://cdn.shopify.com/s/files/1/1367/5207/files/BlurSeamlessBuiltinCamiGSBlack_GSAsphaltGreyB9A1S-BCCJ_1664x.jpg?v=1717571496",
      title: "Blur Seamless Cami ",
      title2: "Black/Asphalt Grey",
      price: 7295,
    },
    {
      id: 4,
      image:
        "https://cdn.shopify.com/s/files/1/1367/5207/files/BlurSeamlessShortGSBlack_GSAsphaltGreyB9A2D-BCCJ2_640x.jpg?v=1717571491",
      title: "Blur Seamless Shorts",
      title2: "Black/Asphalt Grey",
      price: 7295,
    },
    {
      id: 5,
      image:
        "https://cdn.shopify.com/s/files/1/1367/5207/files/RuchedSportsBraWhiteB2A7A-WBBM-0016_1664x.jpg?v=1714127923",
      title: "Blur Seamless Shorts",
      title2: "Black/Asphalt Grey",
      price: 7295,
    },
  ];

  return (
    <section>
      <div className=" ">
        <CardSwiper
          title={"ELEVATE YOURSELF WITH ONE PRECENT CLUB"}
          data={data}
        />
      </div>
    </section>
  );
};

export default Section3;
