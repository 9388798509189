import { auth, db } from "Firestore/Firebase";
import Layout from "components/Layout/Layout";
import DetailMain from "components/OrderDetail/DetailMain";
import { collection, doc, getDoc, onSnapshot, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useLocation, useNavigate } from "react-router-dom";

const OrderDetail = () => {
  const navigation = useNavigate();
  const [user] = useAuthState(auth);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const id = location.state?.id;

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const docRef = doc(db, "users", user?.uid, "orders", id);
        const docRef = doc(db, "orders", id);

        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setData(docSnap.data());
          setLoading(false);
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      } finally {
        // setLoading(false);
      }
    };

    if (user) {
      fetchData();
    } else {
      // setLoading(false);
      console.log("Error fetching orders");
    }
  }, [user, id]);

  const [products, setProducts] = useState(null);

  useEffect(() => {
    try {
      const q = query(
        collection(db, "orders", id, "products")

        // collection(db, "users", user?.uid, "orders", id, "products")
      );
      onSnapshot(q, (querySnapshot) => {
        const cities = [];
        querySnapshot.forEach((doc) => {
          cities.push({ id: doc.id, ...doc.data() });
        });

        setProducts(cities);
      });
    } catch {
      console.log("Error fetching products");
    } finally {
    }
  }, [user, id]);

  const [address, setAddress] = useState([]);

  useEffect(() => {
    try {
      const q = query(
        collection(db, "users", user?.uid, "orders", id, "address")
      );

      onSnapshot(q, (querySnapshot) => {
        const cities = [];
        querySnapshot.forEach((doc) => {
          cities.push({ id: doc.id, ...doc.data() });
        });

        setAddress(cities[0]);
      });
    } catch {
      console.log("Error fetching address");
    } finally {
    }
  }, [user, id]);

  return (
    <Layout loading={loading} title={"Orer Detail"}>
      <DetailMain data={data} products={products} address={address} />
    </Layout>
  );
};

export default OrderDetail;
