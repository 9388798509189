import Button from "components/Layout/Fields/Button";
import Image from "components/Layout/Fields/Image";
import InputTextArea from "components/Layout/Fields/InputTextArea";
import Select from "components/Layout/Fields/Select";
import ModalSection from "components/Layout/ModalSection";
import React, { Fragment, useEffect, useState } from "react";
import { HiMiniXMark } from "react-icons/hi2";
import { MdLoop } from "react-icons/md";
import Currency from "react-currency-formatter";
import { Link, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../config";
import axios from "axios";
import { addDays, format, parseISO } from "date-fns";
import toast from "react-hot-toast";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "Firestore/Firebase";
import { doc, serverTimestamp, updateDoc } from "firebase/firestore";
import { db } from "Firestore/Firebase";
import emailjs from "@emailjs/browser";

const Detail = ({ data, deliveredDate }) => {
  const formatTimestamp = (timestamp) => {
    const milliseconds =
      timestamp?.seconds * 1000 + timestamp?.nanoseconds / 1000000;
    const date = new Date(milliseconds);
    const options = { year: "numeric", month: "short", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  return (
    <div>
      <div className=" flex flex-col gap-1">
        <h1 className=" text-sm   font-light   text-white">Order Number :</h1>

        <h1 className="text-sm   font-light      text-secondary-300 underline">
          {data?.orderid}
        </h1>
        <h1 className=" text-xs uppercase    text-white ">
          Date ordered :{" "}
          <span className="text-xs uppercase    text-white ">
            {formatTimestamp(data?.date)}
          </span>{" "}
        </h1>

        <h1 className=" text-xs uppercase    text-white ">
          Order status :{" "}
          <span className="text-xs uppercase    text-white  ">
            {data?.status}
          </span>{" "}
        </h1>
      </div>
    </div>
  );
};

const Tracking = ({
  openModal,
  products,
  data,
  delivery,
  deliveredDate,
  exchangeLast,
  exchangeValid,
}) => {
  const naviagtion = useNavigate();

  const handleProductClick = (item) => {
    const id = item.productid ? item.productid : item.exchangeSku.split("-")[0];

    naviagtion(`/products/${item.category}/${id}`);
  };

  const formattedDate =
    delivery?.etd && format(parseISO(delivery?.etd), "EEE, MMM d");

  const returnData = data?.returnOrderItems;
  const requestData = data?.requstedItems;

  return (
    <div className="  bg-secondary-300 p-5 flex flex-col gap-5">
      <div className=" flex-row justify-between flex">
        <div>
          <h1 className=" text-base  font-medium tracking-wide">
            SHIPPING 1 OF 1{" "}
          </h1>

          <h1 className=" text-xs tracking-wide uppercase ">
            Tracking Number : <br />
            {delivery?.track_url && (
              <Link
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(
                    delivery?.track_url,
                    "_blank",
                    "noopener noreferrer"
                  );
                }}
              >
                <span className=" underline  font-medium">
                  {data?.shipRocketAwb}
                </span>
              </Link>
            )}
          </h1>
        </div>
        <div className=" flex flex-col gap-1">
          {data?.status === "CANCELLED" ? (
            <h1
              onClick={() => {
                toast.error("Order CANCELLED, unable to track.", {
                  style: {
                    background: "rgba(25,25,25)",
                    color: "white",
                    fontSize: "13px",
                    textTransform: "uppercase",
                  },
                });
              }}
              className="text-black font-medium text-xs underline cursor-pointer uppercase"
            >
              View Tracking Details
            </h1>
          ) : (
            <Fragment>
              {delivery?.etd && !data?.status === "DELIVERED" && (
                <div className=" flex flex-row gap-1">
                  <h1 className="  text-black text-xs    uppercase font-medium">
                    Est Delivery {formattedDate}
                  </h1>
                </div>
              )}

              {(data?.status === "DELIVERED" ||
                data?.status === "return request created") && (
                <h1 className=" text-xs uppercase  font-medium     text-black ">
                  Delivered on :{" "}
                  <span className="text-xs uppercase  font-medium   text-black ">
                    {deliveredDate}
                  </span>{" "}
                </h1>
              )}

              {delivery?.track_url && (
                <Link
                  className=" w-fit"
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(
                      delivery?.track_url,
                      "_blank",
                      "noopener noreferrer"
                    );
                  }}
                >
                  <h1 className="text-black font-medium text-xs underline uppercase cursor-pointer">
                    View Tracking Details
                  </h1>
                </Link>
              )}
            </Fragment>
          )}
        </div>
      </div>

      {(data?.status === "DELIVERED" ||
        data?.status === "return request created") && (
        <div className=" border border-secondary-200 p-4 flex  flex-col lg:flex-row justify-between gap-4">
          {exchangeValid ? (
            <p className=" text-sm  font-light font    ">
              Need to exchange an item ? No problem. You can initiate return of
              any of the products <br className=" hidden md:flex" /> until{" "}
              <span className=" font-medium">{exchangeLast}.</span>
            </p>
          ) : (
            <p className=" text-sm  font-light font    ">
              Unfortunately, product can't be refunded. The return period for
              this order has lapsed.
            </p>
          )}

          <button
            onClick={
              exchangeValid
                ? openModal
                : () => {
                    toast.error(
                      `Exchange is not available for this order. `,
                      {
                        style: {
                          background: "rgba(25,25,25)",
                          color: "white",
                          fontSize: "13px",
                          textTransform: "uppercase",
                        },
                      }
                    );
                  }
            }
            className=" border-2 border-secondary-200 p-3 text-xs   lg:w-44  font-medium "
          >
            Exchange
          </button>
        </div>
      )}

      {products?.map((item, index) => (
        <div key={index} className=" flex  bg-white p-3  gap-4">
          <div
            onClick={() => handleProductClick(item)}
            className=" cursor-pointer   w-24"
          >
            <Image src={item.image} />
          </div>
          <div>
            <h1
              onClick={() => handleProductClick(item)}
              className=" font-medium cursor-pointer tracking-wide"
            >
              {item?.name}
            </h1>
            {item.return && (
              <h1 className=" font-light text-sm">
                Return : <span className=" capitalize"> Confirmed</span>
              </h1>
            )}
            <div>
              <div>
                {item.size !== " " && (
                  <h1 className=" font-light text-sm">
                    Size : <span className=" uppercase">{item?.size}</span>
                  </h1>
                )}
                <h1 className=" font-light text-sm">
                  Color : <span className=" capitalize"> {item?.color}</span>
                </h1>
                <h1 className=" font-light text-sm">
                  Quantity :{" "}
                  <span className=" capitalize"> {item?.quantity}</span>
                </h1>
                <h1 className=" font-light text-sm">
                  Price :{" "}
                  <Currency
                    quantity={item?.price * item?.quantity}
                    currency="inr"
                  />
                </h1>
              </div>
            </div>
          </div>
        </div>
      ))}

      {data?.returnOrderItems && (
        <Fragment>
          <h1 className=" font-medium uppercase text-sm underline">
            Return Products
          </h1>
          <div>
            {Object.entries(returnData).map(([key, item]) => (
              <div key={key}>
                <div className="   bg-white p-3 flex     flex-col   gap-3  ">
                  <div className="  flex justify-between  gap-4 flex-col  md:flex-row">
                    <div className="flex gap-4 ">
                      <div
                        onClick={() => handleProductClick(item)}
                        className=" cursor-pointer   w-24"
                      >
                        <Image
                          src={item.qc_product_image}
                          alt={item.qc_product_name}
                        />
                      </div>
                      <div>
                        <h1
                          onClick={() => handleProductClick(item)}
                          className=" font-medium cursor-pointer tracking-wide"
                        >
                          {item?.name}
                        </h1>
                        <div>
                          <div>
                            {item.qc_size !== " " && (
                              <h1 className=" font-light text-sm">
                                Size :{" "}
                                <span className=" uppercase">
                                  {item.qc_size}
                                </span>
                              </h1>
                            )}
                            <h1 className=" font-light text-sm">
                              Color :{" "}
                              <span className=" capitalize">
                                {" "}
                                {item?.qc_color}
                              </span>
                            </h1>
                            <h1 className=" font-light text-sm">
                              Quantity :{" "}
                              <span className=" capitalize">
                                {" "}
                                {item?.units}
                              </span>
                            </h1>
                            <h1 className=" font-light text-sm">
                              Price :{" "}
                              <Currency
                                quantity={item?.selling_price * item?.units}
                                currency="inr"
                              />
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* {item.returnAwbCode && (
                      <Link
                        className=" w-fit"
                        target="_blank"
                        rel="noopener noreferrer"
                        to={`https://shiprocket.co/tracking/${item.returnAwbCode}`}
                      >
                        <h1 className="text-black font-medium text-xs underline cursor-pointer uppercase">
                          View Tracking Details
                        </h1>
                      </Link>
                    )} */}
                  </div>

                  <div className=" flex flex-col gap-1 ">
                    <h1 className="text-black font-medium text-xs   uppercase">
                      Return Status : {item.returnStatus}
                    </h1>

                    <h1 className="text-black font-medium text-xs   uppercase">
                      Return Request Date : {item.returnOrderDate}
                    </h1>

                    {item.pickupScheduledDate &&
                      item.returnStatus === "return Request Created" && (
                        <h1 className="text-black font-medium text-xs uppercase">
                          Scheduled Pick up Date : {""}
                          {format(
                            new Date(item.pickupScheduledDate),
                            "MMM dd yyyy"
                          )}
                        </h1>
                      )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Fragment>
      )}

      {data?.requstedItems && (
        <Fragment>
          <h1 className=" font-medium uppercase text-sm underline">
            Requested Products
          </h1>
          <div>
            {Object.entries(requestData).map(([key, item]) => (
              <div key={key}>
                <div className="   bg-white p-3 flex flex-col gap-4   ">
                  <div className="flex   justify-between  flex-col md:flex-row  gap-4">
                    <div className="flex gap-4 ">
                      <div
                        onClick={() => handleProductClick(item)}
                        className=" cursor-pointer   w-24"
                      >
                        <Image
                          src={item.qc_product_image}
                          alt={item.qc_product_name}
                        />
                      </div>
                      <div>
                        <h1
                          onClick={() => handleProductClick(item)}
                          className=" font-medium cursor-pointer tracking-wide"
                        >
                          {item?.name}
                        </h1>
                        <div>
                          <div>
                            {item.qc_size !== " " && (
                              <h1 className=" font-light text-sm">
                                Size :{" "}
                                <span className=" uppercase">
                                  {item.qc_size}
                                </span>
                              </h1>
                            )}
                            <h1 className=" font-light text-sm">
                              Color :{" "}
                              <span className=" capitalize">
                                {" "}
                                {item?.qc_color}
                              </span>
                            </h1>
                            <h1 className=" font-light text-sm">
                              Quantity :{" "}
                              <span className=" capitalize">
                                {" "}
                                {item?.units}
                              </span>
                            </h1>
                            <h1 className=" font-light text-sm">
                              Price :{" "}
                              <Currency
                                quantity={item?.selling_price * item?.units}
                                currency="inr"
                              />
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>
                    {item.exhchangeOrderAwb && (
                      <div className=" flex flex-col gap-1  md:items-end">
                        <Link
                          target="_blank"
                          rel="noopener noreferrer"
                          to={`https://shiprocket.co/tracking/${item.exhchangeOrderAwb}`}
                        >
                          <h1 className="text-black font-medium text-xs underline cursor-pointer uppercase">
                            View Tracking Details
                          </h1>
                        </Link>
                      </div>
                    )}
                  </div>
                  {item.exchangeStatus && (
                    <div className=" flex flex-col gap-1 ">
                      <h1 className="text-black font-medium text-xs   uppercase">
                        Order status : {item.exchangeStatus}
                      </h1>
                      {item.etd && item?.exchangeStatus !== "DELIVERED" && (
                        <h1 className="text-black font-medium text-xs   uppercase">
                          Est Delivery :{" "}
                          {format(new Date(item.etd), "MMM dd yyyy")}
                        </h1>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </Fragment>
      )}
    </div>
  );
};
const Address = ({ data = {} }) => {
  const {
    name,
    lastname,
    city,
    pincode,
    address,
    state,
    street,
    deliverycontact,
    deliveryemail,
  } = data || "";

  return (
    <div className="">
      <div className=" border  flex flex-col gap-4  border-secondary-200 p-5">
        <h1 className=" text-base  font-medium tracking-wide text-white">
          ADDRESSES
        </h1>
        <div className=" grid gap-6 lg:gap-0 lg:grid-cols-2">
          <div className="  flex flex-col gap-2">
            <h1 className=" text-sm font-medium tracking-wide  text-white">
              Shipping Address:
            </h1>
            <address>
              <p className="text-secondary-300   text-sm  font-light ">
                {name} {lastname} <br />
                {address}
                <br />
                {street}
                <br />
                {pincode} {city} {state} <br />
                India <br />
              </p>
            </address>
          </div>
          <div className="  flex flex-col gap-2">
            <h1 className=" text-sm font-medium tracking-wide  text-white">
              Contact Info:
            </h1>
            <div>
              <h1 className=" text-secondary-300   text-sm  font-light">
                {deliveryemail}
              </h1>
              <h1 className="text-secondary-300   text-sm  font-light">
                {deliverycontact}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const Payment = ({ data = {} }) => {
  const { status, subtotal, total, coupon, discount } = data || "";

  return (
    <div className="">
      <div className=" border  flex flex-col gap-4  border-secondary-200 p-5">
        <h1 className=" text-base  font-medium tracking-wide text-white">
          PAYMENT
        </h1>
        <div className=" ">
          <div className="  flex flex-col gap-3">
            <div className=" flex flex-col gap-2 border-b  border-secondary-200 pb-5">
              <h1 className=" text-sm font-medium tracking-wide  text-white">
                Order Summary
              </h1>
              <div className="flex  flex-col gap-1">
                <div className=" flex justify-between">
                  <h1 className="  uppercase text-sm  font-normal tracking-wide  text-secondary-300">
                    Subtotal
                  </h1>
                  <h1 className="text-sm font-extralight tracking-wide  text-white">
                    <Currency quantity={subtotal} currency="inr" />
                  </h1>
                </div>
                {discount > 0 && (
                  <div className=" flex justify-between">
                    <h1 className=" text-xs  font-normal uppercase tracking-wide  text-secondary-300">
                      Coupon Discount {coupon?.discount}%{" "}
                      <span className=" text-xs text-primary-100">
                        {" "}
                        ({coupon?.name}){" "}
                      </span>
                    </h1>
                    <h1 className="text-xs font-extralight tracking-wide  text-white">
                      - <Currency quantity={discount} currency="inr" />
                    </h1>
                  </div>
                )}
              </div>
            </div>
            <div className=" flex justify-between">
              <h1 className=" uppercase text-sm  font-semibold tracking-wide  text-secondary-300">
                Total
              </h1>
              <h1 className="text-sm   font-semibold tracking-wide  text-white">
                <Currency quantity={total} currency="inr" />
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DetailMain = ({ data, products, address }) => {
  const [status, setStatus] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const selectStatus = (data) => {
    setStatus(data);
  };
  const openModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const [reason, setReason] = useState("Item defective or doesn't work");
  const [size, setSize] = useState("s");
  const [quantity, setQuantity] = useState(1);
  const [selected, setSelected] = useState(null);
  const [comment, setComment] = useState(null);

  const handleChange = (event) => {
    setReason(event.target.value);
  };
  const selectProduct = (item) => {
    setSelected(item);
  };

  const handleSizeChange = (event) => {
    setSize(event.target.value);
  };
  const handleQuanityChange = (event) => {
    setQuantity(event.target.value);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setStatus(null);
    setSize(null);
    setReason(null);
    setSelected(null);
    setQuantity(1);
    setQuantity(1);
    setSize("s");
    setReason("Item defective or doesn't work");
  };

  const reasons = [
    {
      id: 1,
      value: "Item defective or doesn't work",
      label: "Item defective or doesn't work",
    },
    {
      id: 2,
      value: "Wrong item was sent",
      label: "Wrong item was sent",
    },

    {
      id: 4,
      value: "Product damaged, but shipping box OK",
      label: "Product damaged, but shipping box OK",
    },
    { id: 5, value: "Size not as expected", label: "Size not as expected" },
    {
      id: 5,
      value: "Missing parts or accessories",
      label: "Missing parts or accessories",
    },
  ];

  const sizeOptions = [
    {
      id: 1,
      value: "S",
      label: "S",
    },
    {
      id: 2,
      value: "M",
      label: "M",
    },
    { id: 3, value: "L", label: "L" },
    {
      id: 4,
      value: "XL",
      label: "XL",
    },
  ];

  const options =
    selected &&
    Array(selected?.quantity)
      .fill()
      .map((_, index) => ({
        id: index + 1,
        value: index + 1,
        label: index + 1,
      }));

  const [user] = useAuthState(auth);
  const token = user?.accessToken;

  const [responseData, setResponseData] = useState(null);
  const [loading, setLoading] = useState(false);

  const date = new Date();

  const formattedDate = format(date, "MMM dd yyyy hh:mm a");

  const handleRequest = async () => {
    if (selected) {
      try {
        setLoading(true);
        const response = await axios.post(
          `${BASE_URL}/order/exchange`,
          {
            orderId: data?.orderid,
            productId: selected?.productid,
            size: size || "",
            reason: "Size not as expected",
            quantity: quantity,
            comment: comment,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        await updateDoc(
          doc(db, "orders", data.orderid, "products", selected?.id),
          {
            return: true,
            returnDate: formattedDate,
          }
        );

        const sendData = {
          date: formattedDate,
          id: data?.orderid,
          customer: data?.name,
          productid: selected?.productid,
          name: selected?.name,
          price: selected?.price,
          category: selected?.category,
          size: size || "",
          quantity: quantity,
          reason: "Size not as expected",
          comment: comment,
          img: selected?.image,
        };

        await emailjs.send(
          "service_0ureci6",
          "template_r3qqttp",
          sendData,
          "VLVKkxM5qiYxKD0hn"
        );

        await updateDoc(doc(db, "orders", data.orderid), {
          return: true,
          requestDate: formattedDate,
        });

        toast.success(response.data.data.msg, {
          style: {
            background: "rgba(25,25,25)",
            color: "white",
            fontSize: "13px",
            textTransform: "uppercase",
          },
        });

        closeModal();
        setResponseData(response.data);
        console.log("Response:", response.data);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } catch (error) {
        console.error("Error during sign-in:", error);
        console.log(error.message.Error);
        toast.error(error.message, {
          style: {
            background: "rgba(25,25,25)",
            color: "white",
            fontSize: "13px",
            textTransform: "uppercase",
          },
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const apiEndpoint = `${BASE_URL}/order/track-order?shipRocketAwb=${data?.shipRocketAwb}`;

  const [delivery, setDelivery] = useState(null);
  // const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Replace with your API endpoint
    const fetchData = async () => {
      try {
        const response = await axios.get(apiEndpoint);
        setDelivery(response.data.data.data);
      } catch (err) {
        setError(err.message);
      } finally {
        // setLoading(false);
      }
    };

    fetchData();
  }, [data]);

  const delivered = delivery?.shipment_track[0].delivered_date;
  const deliveredDate = delivered && format(delivered, "MMM d, yyyy");
  const newDate = delivered && addDays(delivered, 5);
  const exchangeLast = delivered && format(newDate, "MMM d, yyyy");
  const today = format(new Date(), "MMM d, yyyy");
  const exchangeValid = exchangeLast >= today;

  return (
    <Fragment>
      <div className="bg-secondary-100  py-10  ">
        <div className=" container   px-5   xl:px-44 flex flex-col gap-8 pb-20 ">
          <h1 className="font-semibold  text-lg md:text-2xl uppercase text-white tracking-wide">
            Order details
          </h1>
          <Detail
            deliveredDate={deliveredDate}
            data={data}
            delivery={delivery}
          />
          <Tracking
            exchangeValid={exchangeValid}
            exchangeLast={exchangeLast}
            deliveredDate={deliveredDate}
            openModal={openModal}
            products={products}
            data={data}
            delivery={delivery}
          />
          <Address data={address} />
          <Payment data={data} />
        </div>
      </div>

      <ModalSection isOpen={isModalOpen} onClose={closeModal} className="">
        <Fragment>
          <div className="  flex flex-col gap-4 ">
            <div className=" flex justify-between items-center">
              <h1 className=" text-secondary-100 text-sm  tracking-wider font-medium">
                Exchange
              </h1>
              <button onClick={closeModal}>
                <HiMiniXMark size={25} />
              </button>
            </div>

            <div className=" flex flex-col gap-4">
              {status === null ? (
                <div className=" flex flex-col gap-2">
                  {products?.map((item, index) => (
                    <Fragment>
                      <div
                        key={index}
                        className=" flex flex-row   justify-between items-start flex-wrap gap-4  border p-3"
                      >
                        <div className=" flex   gap-4 ">
                          <div className="    w-20">
                            <Image src={item.image} />
                          </div>
                          <div>
                            <h1 className=" font-medium  text-sm tracking-wide">
                              {item?.name}
                            </h1>
                            <div>
                              <div>
                                <h1 className=" font-light text-xs">
                                  Price :{" "}
                                  <span>
                                    {" "}
                                    <Currency
                                      quantity={item?.price * item?.quantity}
                                      currency="inr"
                                    />
                                  </span>
                                </h1>
                                <h1 className=" font-light text-xs">
                                  <span>
                                    {item.size !== " " && (
                                      <h1 className=" font-light text-xs">
                                        Size :{" "}
                                        <span className=" uppercase">
                                          {item?.size}
                                        </span>
                                      </h1>
                                    )}
                                  </span>
                                </h1>
                                <h1 className=" font-light text-xs">
                                  Color : <span> {item?.color}</span>
                                </h1>
                                <h1 className=" font-light text-xs">
                                  Quantity : <span> {item?.quantity}</span>
                                </h1>
                              </div>
                            </div>
                          </div>
                        </div>

                        {item.return ? (
                          <div>
                            <h1 className=" text-xs  uppercase">
                              Return Requested Created
                            </h1>
                          </div>
                        ) : (
                          <Fragment>
                            {item.size ? (
                              <div className="  sm:w-auto">
                                <button
                                  onClick={() => selectProduct(item)}
                                  className={` ${
                                    selected?.id === item.id
                                      ? "  bg-primary-100"
                                      : "bg-secondary-100"
                                  } px-5 py-2 rounded-lg text-white text-xs w-full`}
                                >
                                  {selected?.id === item.id
                                    ? "Selected"
                                    : "Select"}
                                </button>
                              </div>
                            ) : (
                              <h1 className=" text-xs  uppercase">
                                Product Not eligible for exchange
                              </h1>
                            )}
                          </Fragment>
                        )}
                      </div>
                    </Fragment>
                  ))}
                </div>
              ) : (
                <div className=" flex flex-row   justify-between items-start  border p-3">
                  <div className=" flex   gap-4 ">
                    <div className="    w-20">
                      <Image src={selected.image} />
                    </div>
                    <div>
                      <h1 className=" font-medium  text-sm tracking-wide">
                        {selected?.name}
                      </h1>
                      <div>
                        <div>
                          <h1 className=" font-light text-xs">
                            Price :{" "}
                            <span>
                              {" "}
                              <Currency
                                quantity={selected?.price * selected?.quantity}
                                currency="inr"
                              />
                            </span>
                          </h1>
                          <h1 className=" font-light text-xs">
                            <span>
                              {selected.size !== " " && (
                                <h1 className=" font-light text-xs">
                                  Size :{" "}
                                  <span className=" uppercase">
                                    {selected?.size}
                                  </span>
                                </h1>
                              )}
                            </span>
                          </h1>
                          <h1 className=" font-light text-xs">
                            Color : <span> {selected?.color}</span>
                          </h1>
                          <h1 className=" font-light text-xs">
                            Quantity : <span> {selected?.quantity}</span>
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {status !== null && (
                <Fragment>
                  <div className=" w-full  flex flex-col gap-5   ">
                    {/* <Select
                      required={true}
                      label={"Reason"}
                      id="qty"
                      onChange={handleChange}
                      value={reason}
                      options={reasons}
                      color={"text-secondary-100"}
                      className="  text-secondary-100"
                    /> */}

                    {selected.size !== " " && (
                      <Select
                        required={true}
                        label={"Size"}
                        id="size"
                        onChange={handleSizeChange}
                        value={size}
                        options={sizeOptions}
                        color={"text-secondary-100"}
                        className="  text-secondary-100"
                      />
                    )}
                    <Select
                      required={true}
                      label={"Quantity"}
                      id="qty"
                      onChange={handleQuanityChange}
                      value={quantity}
                      options={options}
                      color={"text-secondary-100"}
                      className="  text-secondary-100"
                    />

                    <InputTextArea
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      placeholder={"Max. 200 characters"}
                      label={"Comment (optional)"}
                      color={"text-secondary-100"}
                      className="  text-secondary-100"
                    />
                  </div>
                </Fragment>
              )}
            </div>

            {status === null ? (
              <div className=" ">
                <Button
                  className={" flex-row flex gap-1"}
                  onClick={() => {
                    if (selected) {
                      selectStatus("exchange");
                    } else {
                      toast.error("Select Product", {
                        style: {
                          background: "rgba(25,25,25)",
                          color: "white",
                          fontSize: "13px",
                          textTransform: "uppercase",
                        },
                      });
                    }
                  }}
                >
                  {" "}
                  <MdLoop size={20} /> Exchange
                </Button>
              </div>
            ) : (
              <Button
                className={" flex-row flex gap-1"}
                onClick={() => {
                  if (selected.size === size) {
                    toast.error("Same size can not be exchange", {
                      style: {
                        background: "rgba(25,25,25)",
                        color: "white",
                        fontSize: "13px",
                        textTransform: "uppercase",
                      },
                    });
                  } else {
                    handleRequest();
                  }
                }}
                disabled={loading}
              >
                {" "}
                <MdLoop size={20} /> Next
              </Button>
            )}
          </div>
        </Fragment>
      </ModalSection>
    </Fragment>
  );
};

export default DetailMain;
