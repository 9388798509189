import FooterData from "Data/FooterData";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
const data = FooterData;
const Footer = () => {
  return (
    <Fragment>
      <div className="bg-secondary-100  border-t   border-border-100 ">
        <div className="   xl:container px-5  ">
          <div className="  pt-10 lg:pt-20 xl:px-20 flex flex-col   gap-16">
            <div className=" flex flex-col  lg:grid  lg:grid-cols-2     gap-10 lg:gap-32">
              <div className=" flex flex-col gap-10  lg:flex-row justify-between">
                <div className="  flex flex-col gap-2">
                  <h1 className=" text-white font-medium sm:text-lg text-base tracking-wide">
                    SUPPORT
                  </h1>
                  <ul>
                    {data.support.map((item, index) => (
                      <li key={index}>
                        <Link
                          to={item.path}
                          className={
                            "    text-secondary-300 hover:text-white  text-sm font-light"
                          }
                        >
                          {item.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="  flex flex-col gap-2">
                  <h1 className=" text-white font-medium sm:text-lg text-base  tracking-wide">
                    POLICY LINKS
                  </h1>
                  <ul className=" ">
                    {data.terms.map((item, index) => (
                      <li key={index}>
                        <Link
                          to={item.path}
                          className={
                            "    text-secondary-300 hover:text-white    text-sm font-light"
                          }
                        >
                          {item.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className=" flex flex-col gap-10  lg:flex-row justify-between">
                {/* <div className="  flex flex-col gap-2">
                  <h1 className=" text-white   font-medium sm:text-lg      text-base   tracking-wide">
                    OUR STORY
                  </h1>
                  <ul>
                    {data.ourstory.map((item, index) => (
                      <li key={index}>
                        <Link
                          className={
                            "text-secondary-300 hover:text-white    text-sm font-light"
                          }
                        >
                          {item.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div> */}
                <div className="  flex flex-col gap-2">
                  <h1 className=" text-white font-medium sm:text-lg      text-base  tracking-wide">
                    STAY UP TO DATE
                  </h1>
                  <ul className=" flex gap-2 ">
                    {data.social.map((item, index) => (
                      <li
                        key={index}
                        className=" flex rounded-full  overflow-hidden"
                      >
                        <Link
                          to={item.path}
                          target="blank"
                          className={`  hover:bg-gray-800 p-2`}
                        >
                          <item.Icon size={22} className=" text-white " />
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            <div className="border-t  py-10   border-border-100 ">
              <div className=" flex justify-between items-center gap-5 ">
                <div className=" flex gap-2">
                  {data.payAccept.map((item, index) => (
                    <div
                      key={index}
                      className="  w-10 bg-white rounded overflow-hidden"
                    >
                      <img className=" w-full h-full  " src={item.img} alt="" />
                    </div>
                  ))}
                </div>

                <h1 className=" uppercas text-xs  text-secondary-300 font-light">
                  © {data.author}, {data.year}. <br className=" sm:hidden" />{" "}
                  ALL RIGHTS RESERVED.
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Footer;
