import { auth, db } from "Firestore/Firebase";
import ProductsCards from "components/Cards/ProductsCards";

import BeforeModal from "components/Wishlist/BeforeModal";
import { collection, onSnapshot, query } from "firebase/firestore";
import React, { Fragment, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";

import { useNavigate } from "react-router-dom";

const ProductsSection = ({ data, title, title2 }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [select,setSelect]=useState(null)
  const openModal = (item) => {
    setIsModalOpen(!isModalOpen);
    setSelect(item)
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setSelect(null)

  };

  const [user] = useAuthState(auth);

  const [wishlist, setWishlist] = useState(null);

  useEffect(() => {
    try {
      const q = query(collection(db, "users", user?.uid, "wishlist"));

      onSnapshot(q, (querySnapshot) => {
        const cities = [];
        querySnapshot.forEach((doc) => {
          cities.push({ id: doc.id, ...doc.data() });
        });
        setWishlist(cities);
      });
    } catch {
      console.log("Error fetching data");
    }
  }, [user]);


  return (
    <Fragment>
      <section>
        <div className="   flex  flex-col gap-5 md:gap-10 w-full ">
          <div>
            <h1 className=" text-secondary-300  font-semibold text-sm uppercase">
              {title2}
            </h1>

            <h1 className=" text-white font-semibold text-xl  md:text-2xl uppercase">
              {title}
            </h1>
          </div>

          <div className=" ">
            <div className=" grid grid-cols-2  lg:grid-cols-3 xl:grid-cols-4 gap-3">
              {data?.map((item, index) => (
                <ProductsCards
                  wishlist={wishlist}
                  user={user}
                  openModal={openModal}
                  item={item}
                  index={index}
                />
              ))}
            </div>
          </div>
        </div>
      </section>

      <BeforeModal select={select} isModalOpen={isModalOpen} closeModal={closeModal} />
    </Fragment>
  );
};

export default ProductsSection;
