import States from "Data/States";
import { db } from "Firestore/Firebase";

import Button from "components/Layout/Fields/Button";
import Button4 from "components/Layout/Fields/Button4";
import InputText from "components/Layout/Fields/InputText";
import Select from "components/Layout/Fields/Select";
import ModalSection from "components/Layout/ModalSection";
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import React, { Fragment, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FiPlus } from "react-icons/fi";
import { HiMiniXMark } from "react-icons/hi2";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../config";
import { format, parseISO } from "date-fns";
import { TbTruckDelivery } from "react-icons/tb";

const Address = ({
  createOrder,
  mainAddress,
  addressList,
  userDetails = {},
  user,
  addressEmail,
  addressNumber,
  setAddressEmail,
  setAddressNumber,
  load,
}) => {
  const { name, email } = userDetails || "";

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const initialValue = {
    email: "",
    phone: "",
    name: "",
    lastname: "",
    pincode: "",
    street: "",
    address: "",
    city: "",
    state: "AP",
  };
  const [errors, setErrors] = useState("");

  const [formData, setFormData] = useState(initialValue);

  const openModal = () => {
    setIsModalOpen(!isModalOpen);
    setFormData((prevState) => ({
      ...prevState,
      email: email,
    }));
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setFormData(initialValue);
    setErrors();
  };

  const validate = () => {
    let tempErrors = {};
    if (!formData?.email) tempErrors.email = "Email is required";
    if (!formData?.phone) tempErrors.phone = "Phone is required";
    if (!formData?.name) tempErrors.name = "First Name is required";
    if (!formData?.lastname) tempErrors.lastname = "Last Name is required";
    if (!formData?.pincode) tempErrors.pincode = "Pincode is required";
    if (!formData?.street) tempErrors.street = "Street is required";
    if (!formData?.city) tempErrors.city = "City is required";
    if (!formData?.state) tempErrors.state = "State is required";
    if (!formData?.address) tempErrors.address = "Address is required";

    setErrors(tempErrors);
    return Object.keys(tempErrors)?.length === 0;
  };

  const addAddress = async (e) => {
    e.preventDefault();

    if (validate()) {
      setLoading(true);
      try {
        const addressData = {
          email: formData.email,
          phone: formData.phone,
          firstname: formData.name,
          lastname: formData.lastname,
          pincode: formData.pincode,
          street: formData.street,
          address: formData.address,
          state: formData.state,
          name: formData.name,
          city: formData.city,
          createdAt: serverTimestamp(),
        };

        const docRef = await addDoc(
          collection(db, "users", user?.uid, "address"),
          addressData
        );

        await updateDoc(doc(db, "users", user?.uid, "address", docRef.id), {
          id: docRef.id,
        });

        const userDocRef = doc(db, "users", user?.uid);
        await updateDoc(userDocRef, {
          mainaddress: docRef?.id,
          phone: formData.phone,
        });

        window.location.reload();

        toast.success("Address Added Successfully", {
          style: {
            background: "rgba(25,25,25)",
            color: "white",
            fontSize: "13px",
            textTransform: "uppercase",
          },
        });
        setLoading(false);
        closeModal();
        setFormData(initialValue);
      } catch (error) {
        console.error("Error adding address: ", error);
        toast.error("Failed to add address", {
          style: {
            background: "rgba(25,25,25)",
            color: "white",
            fontSize: "13px",
            textTransform: "uppercase",
          },
        });
      }
    } else {
      toast.error("Please fill all fields", {
        style: {
          background: "rgba(25,25,25)",
          color: "white",
          fontSize: "13px",
          textTransform: "uppercase",
        },
      });
    }
  };

  useEffect(() => {
    setAddressEmail(email);
    setAddressNumber(mainAddress?.phone);
    setFormData((prevState) => ({
      ...prevState,
      email: email,
      phone: mainAddress?.phone,
    }));
  }, [email, mainAddress]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const updateMainAddress = (id, phone) => {
    const washingtonRef = doc(db, "users", user?.uid);
    updateDoc(washingtonRef, {
      mainaddress: id,
      phone: phone || "",
    }).then(() => {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    });
    toast.success("Main address updated", {
      style: {
        background: "rgba(25,25,25)",
        color: "white",
        fontSize: "13px",
        textTransform: "uppercase",
      },
    });
  };





  return (
    <Fragment>
      <div className=" flex flex-col gap-8">
        <div className=" bg-secondary-300 p-4 flex justify-between items-center ">
          <h1 className=" uppercase text-secondary-100  text-base">
            ADDRESSES
          </h1>
        </div>

        <div className=" flex flex-col gap-4">
          <div className=" flex flex-row justify-between items-center">
            <h1 className=" text-lg text-white tracking-wider  font-extralight uppercase">
              Enter A Shipping Address
            </h1>
            <Link to={"/address"}>
              <h1 className=" underline text-xs  cursor-pointer  text-secondary-300">
                View address book
              </h1>
            </Link>
          </div>
          {addressList?.length > 0 && (
            <div className="flex flex-col gap-4 ">
              <Fragment>
                {addressList?.map((item, index) => (
                  <div
                    onClick={() =>
                      userDetails?.mainaddress === item.id
                        ? null
                        : updateMainAddress(item?.id, item?.phone)
                    }
                    key={index}
                    className=" border rounded cursor-pointer  border-secondary-200 p-4"
                  >
                    <div className=" flex flex-col gap-5 md:gap-0  md:flex-row justify-between">
                      <div className=" flex flex-row gap-3 ">
                        <div className="  ">
                          <div
                            className={`  
               ${userDetails?.mainaddress === item.id ? "" : "cursor-pointer"}
               flex gap-2 items-center  `}
                          >
                            <div
                              className={`
                   ${
                     userDetails?.mainaddress === item.id
                       ? " border-white "
                       : " border-secondary-200 border-2  "
                   }
               p-1 border  rounded-full cursor-pointer `}
                            >
                              <div
                                className={` ${
                                  userDetails?.mainaddress === item.id
                                    ? "bg-white "
                                    : "  "
                                }
                     p-1.5 rounded-full `}
                              ></div>
                            </div>
                          </div>
                        </div>

                        <address>
                          <p className=" text-sm   text-white  font-normal">
                            {item?.name} {item?.lastname} {""}
                            {item?.address} {item.street}
                            {""} / {item?.pincode} {item?.city} {item?.state}{" "}
                            {"India"}
                          </p>
                        </address>
                      </div>
                    </div>
                  </div>
                ))}
              </Fragment>
            </div>
          )}
          <div onClick={openModal} className=" w-fit flex gap-0 flex-row">
            <FiPlus color="white" size={20} />
            <h1 className="  flex text-white underline text-sm cursor-pointer">
              Add Address
            </h1>
          </div>

          <div className=" pt-5 flex flex-col gap-10">
         
            <div className="flex flex-col gap-10">
              <div className=" flex flex-col gap-5">
                <h1 className=" text-lg text-white tracking-wider  font-extralight uppercase">
                  Enter Contact Info
                </h1>

                <div className=" md:col-span-2  flex flex-col  md:grid grid-cols-2 gap-5">
                  <div className="   ">
                    <InputText
                      label={"EMAIL "}
                      required={true}
                      name="email"
                      value={addressEmail}
                      onChange={(e) => setAddressEmail(e.target.value)}
                      type={"email"}
                      className=""
                      placeholder={"Email"}
                      color={""}
                    />
                  </div>
                  <div className="   ">
                    <InputText
                      label={"PHONE NUMBER"}
                      value={addressNumber}
                      onChange={(e) => setAddressNumber(e.target.value)}
                      name="phone"
                      type={"number"}
                      className=""
                      placeholder={"Phone Number"}
                    />
                  </div>
                </div>
              </div>
              <div className=" ">
                <div className=" flex flex-col gap-5">
                  <Button4
                    disabled={load}
                    onClick={mainAddress ? createOrder : openModal}
                    className={"uppercase text-base  py-4"}
                  >
                    {mainAddress ? "Continue to payment method" : "Add Address"}
                  </Button4>

                  <p className=" text-xs text-secondary-300">
                    By continuing, I confirm that I have read and accept the{" "}
                    <Link
                      to={"/terms-and-conditions"}
                      className=" underline cursor-pointer"
                    >
                      Terms and Conditions
                    </Link>{" "}
                    and the{" "}
                    <Link
                      to={"/privacy-policy"}
                      className=" underline cursor-pointer"
                    >
                      Privacy Policy
                    </Link>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalSection
        isOpen={isModalOpen}
        onClose={closeModal}
        className="  lg:w-[600px]"
      >
        <Fragment>
          <div className=" px-0 pt-3 pb-10 md:p-3 flex flex-col gap-4 ">
            <div className=" flex justify-between items-center">
              <h1 className=" text-secondary-100 text-lg  tracking-wider font-medium">
                Add Address
              </h1>
              <button onClick={closeModal}>
                <HiMiniXMark size={25} />
              </button>
            </div>

            <form
              onSubmit={addAddress}
              action=""
              className="  flex flex-col gap-2"
            >
              <div className=" flex flex-col gap-5">
                <div className=" flex flex-col gap-5">
                  Enter Contact Info
                  <div className=" md:col-span-2  flex flex-col  md:grid grid-cols-2 gap-5">
                    <div className="   ">
                      <InputText
                        label={"EMAIL "}
                        required={true}
                        value={formData?.email}
                        name="email"
                        onChange={handleChange}
                        type={"email"}
                        className=""
                        placeholder={"Email"}
                        color={"  text-secondary-100"}
                        error={errors?.email}
                      />
                    </div>
                    <div className="   ">
                      <InputText
                        label={"PHONE NUMBER"}
                        required={true}
                        value={formData?.phone}
                        name="phone"
                        onChange={handleChange}
                        type={"number"}
                        className=""
                        placeholder={"Phone Number"}
                        color={"  text-secondary-100"}
                        error={errors?.phone}
                      />
                    </div>
                  </div>
                </div>

                <div className=" col-span-2  flex flex-col   gap-5">

                <div className=" col-span-2   flex flex-col  md:grid grid-cols-2 gap-5">



                  <div className=" ">
                    <InputText
                      label={"FIRST NAME "}
                      required={true}
                      value={formData.name}
                      name="name"
                      onChange={handleChange}
                      type={"text"}
                      className=""
                      placeholder={"First Name"}
                      error={errors?.name}
                      color={"  text-secondary-100"}
                    />
                  </div>
                  <div>
                    <InputText
                      label={"LAST NAME "}
                      required={true}
                      value={formData?.lastname}
                      name="lastname"
                      onChange={handleChange}
                      type={"text"}
                      className=""
                      placeholder={"Last Name"}
                      color={"  text-secondary-100"}
                      error={errors?.lastname}
                    />
                  </div>
</div>


                  <div className="">
                    <InputText
                      label={"PIN CODE "}
                      required={true}
                      value={formData?.pincode}
                      name="pincode"
                      onChange={handleChange}
                      type={"text"}
                      className=""
                      placeholder={"PIN code"}
                      color={"  text-secondary-100"}
                      error={errors?.pincode}
                    />
                  </div>
                  <div className="   ">
                    <InputText
                      label={"STREET "}
                      required={true}
                      value={formData?.street}
                      name="street"
                      onChange={handleChange}
                      type={"text"}
                      className=""
                      placeholder={"Street"}
                      color={"  text-secondary-100"}
                      error={errors?.street}
                    />
                  </div>



                  <div className="   col-span-2">
                    <InputText
                      label={"ADDRESS"}
                      required={true}
                      value={formData?.address}
                      name="address"
                      onChange={handleChange}
                      type={"text"}
                      className=""
                      placeholder={"Address"}
                      color={"  text-secondary-100"}
                      error={errors?.address}
                    />
                  </div>

                  <div className=" col-span-2   flex flex-col  md:grid grid-cols-2 gap-5">
                    <div className="   ">
                      <InputText
                        color={"  text-secondary-100"}
                        label={"CITY "}
                        required={true}
                        value={formData?.city}
                        name="city"
                        onChange={handleChange}
                        type={"text"}
                        className=""
                        placeholder={"City"}
                        error={errors?.city}
                      />
                    </div>
                    <div className=" w-full   ">
                      <Select
                        error={errors?.state}
                        color={"  text-secondary-100"}
                        required={true}
                        label={"STATE"}
                        id="qty"
                        value={formData?.state}
                        name="state"
                        onChange={handleChange}
                        options={States}
                        className=""
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className=" pt-5">
                <div className=" grid    md:grid-cols-2  gap-3 md:gap-5">
                  {/* <Button5
                    disabled={loading}
                    className={
                      "whitespace-pre md:px-5  py-3 lg:px-10  text-sm  bg-secondary-100   hover:bg-secondary-300 uppercase     text-white flex items-center gap-2  hover:border-border-100"
                    }
                  >
                    Save
                  </Button5> */}

                  <Button className={" "} disabled={loading}>
                    Save
                  </Button>

                  <Button
                    background={"bg-gray-700"}
                    onClick={closeModal}
                    className={
                      " text-lg py-3 md:py-3 uppercase bg-gray-700   hover:bg-secondary-300  px-20"
                    }
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </Fragment>
      </ModalSection>
    </Fragment>
  );
};

export default Address;
