import Layout from "components/Layout/Layout";
import PolicyMain from "components/Policy/PolicyMain";
import React from "react";

const Exchange = () => {
  const data = [
    {
      id: 1,
      title: "Products purchased from One Percent Club India Online Store",
      description: `One Percent Club India is committed to your happiness. We want you to be as pleased with your purchase as we are in serving you. If you're not happy for any reason, we will gladly accept your return within 5 days of your order delivery date. In order to make your exchange simple and inexpensive, it’s on the house and we’re picking up the tab!`,
    },
    {
      id: 2,
      title: "Shipping",
      description: `Purchased items will be delivered in 5-7 days.`,
    },
    {
      id: 2,
      title: "Follow the below steps to Exchange an Item:",
      description: `        - Visit Order Detais. \n
            - Select on exchange button. \n
            - Select Size. \n
            - Send Request for Exchnage.
    `,
    },

    {
      id: 8,
      title: "  Initiating an Exchange:",
      description: `
            Eligibility: \n
            - Products must be in unused, unworn, and resalable condition and shall be eligible for exchange. \n
            - The product must be returned in its original packaging with all tags intact. \n
         `,
    },
    {
      id: 9,
      title: " Limitation:",
      description: `
            - Each customer is entitled to one exchange per order. \n
            - Once we receive the returned item and verify its condition, we will process the exchange order. (Our standard delivery timeline will apply). \n
            - The returned item shall be picked up from the order delivery address only. \n
         `,
    },

   

 
  ];
  return (
    <Layout title={"Exchange & Return Policy"}>
      <PolicyMain title={"Exchange & Return Policy"} data={data} />
    </Layout>
  );
};

export default Exchange;
