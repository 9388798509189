import React, { Fragment } from "react";
import { IoLocationOutline, IoPhonePortraitOutline } from "react-icons/io5";
import { CiMail } from "react-icons/ci";
import { Link } from "react-router-dom";
import { PiChatDots } from "react-icons/pi";
const ContactMain = () => {
    const contact = [
        {
          id: 1,
          title: "Chat with us",
          subtitle: `10:00 AM - 07:00 PM \n 6 days a week`,
          path: "https://wa.me/917003351046", // WhatsApp link
          icon: PiChatDots,
        },
        {
          id: 2,
          title: "Call us",
          subtitle: `+9170033 51046\nProducts & Orders:  10:00 AM - 07:00 PM, \n 6 days a week`,
          path: "tel:+9170033 51046",
          icon: IoPhonePortraitOutline,
        },
        {
          id: 3,
          title: "We'll reply within",
          subtitle: `five business days`,
          path: "mailto:onepercentclubaw@gmail.com", // mailto link
          icon: CiMail,
        },
        {
          id: 4,
          title: "Location",
          subtitle: `1 B(First floor)\nTriveni co-operative,DB 167,Street no. 267,\nKolkata-156`,
          path: "https://g.co/kgs/whQrdN3",
          icon: IoLocationOutline,
        },
      ];

  return (
    <div className="bg-secondary-100  py-10   ">
      <div className=" container   px-5 xl:px-16 flex flex-col gap-20 pb-10 ">
        <div className=" border-b border-secondary-200 pb-4">
          <h1 className=" text-white">Contact Us</h1>
        </div>
        <div className="  grid md:grid-cols-2 2xl:grid-cols-4 gap-10 md:gap-5  items-start  pb-10">
            {contact.map((item, index) => (
                <Link
                key={index}
                to={item.path}
                target="blank"
                className="flex  flex-col items-center justify-center space-y-3"
                >
                <item.icon className="text-secondary-300" size={40} />
                <div className=" flex  flex-col items-center justify-center space-y-1 ">
                    <h1 className="  text-secondary-300 font-medium text-sm">
                    {item.title}
                    </h1>

                    {item.subtitle.split("\n").map((line, index) => (
                    <Fragment>
                        <p
                        className="  text-secondary-300   font-extralight text-sm"
                        key={index}
                        >
                        {line}{" "}
                        </p>
                    </Fragment>
                    ))}
                </div>
                </Link>
            ))}
        </div>

        {/* <div className=" bg-secondary-300 p-10 md:items-center flex md:flex-row flex-col  gap-5 md:gap-0 justify-between">
          <h1 className=" text-xl font-semibold  ">Need some help?</h1>
          <div className=" flex flex-col gap-5">
            <div className="  flex  flex-col md:flex-row gap-2">
              <h1 className="  text-sm  flex ">Email:</h1>
              <span className=" text-sm font-semibold underline">
                ONEPERCENTCLUB@GMAIL.COM
              </span>
            </div>
            <div className="  flex  flex-col md:flex-row gap-2">
              <h1 className="  text-sm  flex ">Phone Number :</h1>
              <span className=" text-sm font-semibold underline">
                +91 9123011684
              </span>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default ContactMain;
