import React, { Fragment } from "react";
import VideoSection from "./VideoSection";
import Section2 from "./Section2";
import Section3 from "./Section3";
import OurStory from "./OurStory";

const HomeMain = ({videoSrc}) => {
  return (
    <Fragment>
      <VideoSection  videoSrc={videoSrc}/>
      <div className="bg-secondary-100  py-10  ">
        <div className=" container   px-5 lg:px-16 flex flex-col gap-10 ">
          <Section2 />
          <Section3 />
        </div>
        <OurStory />
      </div>
    </Fragment>
  );
};

export default HomeMain;
