import React, { Fragment } from "react";

const PolicyMain = ({ data, title }) => {
  return (
    <div className="bg-secondary-100  py-10  ">
      <div className=" container   px-5 lg:px-16 flex flex-col gap-10 pb-20 ">
        <h1 className="   tracking-wide  font-semibold text-3xl text-white">
          {title}
        </h1>
        <div className=" flex flex-col gap-5">
          {data.map((item, index) => (
            <div key={index} className=" flex flex-col gap-2">
              <span className=" text-white font-medium text-sm ">
                {" "}
                {item.title}
              </span>{" "}
              <p className=" text-secondary-300 text-sm   font-light">
                {item.description.split("\n").map((line, index) => (
                  <Fragment>
                    <p className=" mb-2" key={index}>
                      {line}{" "}
                    </p>
                  </Fragment>
                ))}
              </p>
            </div>
          ))}
        </div>

        <div className=" bg-secondary-300 p-10 md:items-center flex md:flex-row flex-col  gap-5 md:gap-0 justify-between">
          <h1 className=" text-xl font-semibold  ">Need some help?</h1>
          <div className=" flex flex-col gap-5">
            <div className="  flex  flex-col md:flex-row gap-2">
              <h1 className="  text-sm  flex ">Email:</h1>
              <span className=" text-sm font-semibold underline">
                ONEPERCENTCLUB@GMAIL.COM
              </span>
            </div>
            <div className="  flex  flex-col md:flex-row gap-2">

              <h1 className="  text-sm  flex ">Phone Number :</h1>
              <span className=" text-sm font-semibold underline">
              +9170033 51046
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PolicyMain;
