

         import Button2 from "components/Layout/Fields/Button2";
         import Image from "components/Layout/Fields/Image";
         import React from "react";
         
         const OurStory = () => {
           const Img =
             "https://row.gymshark.com/_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2Fwl6q2in9o7k3%2F3vsWfCtWGyeZ5RF62NagZq%2F573725af6cb9cc83612ad4c0b8de9647%2FW_Desktop_3840x1140-1.jpg&w=1664&q=85";
           return (
             <div className=" py-14">
               <div className=" w-full h-[600px] md:h-[500px] relative bg-no-repeat bg-center     ">
                 <Image className={""} src={Img} />
                 <div
                   style={{
                     background:
                       "linear-gradient(267deg, transparent 0%, #0000006b 90.28%)",
                   }}
                   className="
                 md:w-auto
                  w-full
                  absolute bottom-0  z-10  h-full px-5  md:px-20 py-10   flex items-end md:items-center"
                 >
                   <div className=" flex flex-col gap-5 w-full">
                     <h1 className="   text-xl md:text-5xl font-semibold text-white">
                       OUR STORY
                     </h1>
                     <p className="  text-white text-sm">
                       And even more new reasons to go gym. See you there.
                     </p>
                     <div className=" flex flex-col md:flex-row gap-3">
                       <Button2
                         disabled={false}
                         //  onClick={''}
                         className={"  "}
                       >
                         JOIN OUR CLUB
                       </Button2>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           );
         };
         
         export default OurStory;
         