import Image from "components/Layout/Fields/Image";
import React, { Fragment } from "react";
import Empty from "assets/images/no-wishlist.png";
import Currency from "react-currency-formatter";

import Button2 from "components/Layout/Fields/Button2";
import Button3 from "components/Layout/Fields/Button3";
import { useNavigate } from "react-router-dom";
import { FaHeart } from "react-icons/fa6";
import Button from "components/Layout/Fields/Button";

const EmptyWishlist = ({navigate}) => {
  return (
    <div className=" py-20">
      <div className=" flex flex-col justify-center items-center    gap-5 ">
        <div className=" w-44">
          <Image src={Empty} />
        </div>
        <div className="  flex flex-col  text-center gap-1">
          <h1 className="text-lg text-white  font-semibold tracking-wide">
            YOUR WISHLIST IS EMPTY
          </h1>
          <p className=" text-secondary-300 text-xs">
            Any items that you save while browsing, <br /> will be added here,
            to your wishlist.
          </p>
        </div>

        <div className=" flex flex-col gap-2  w-64">
          <Button2 onClick={()=>navigate('/shop-men')} className={" uppercase  font-medium"}>Shop Mens</Button2>
          <Button3
          onClick={()=>navigate('/shop-women')}
          disabled={false} className={" border"}>
            Shop Women
          </Button3>
        </div>
      </div>
    </div>
  );
};

const Cards = ({ item, removeWishlist , navigate }) => {

  const handleProductClick = (item) => {
    navigate(`/products/${item.category}/${item.id}`);

  };



  return (
    <Fragment>
      <div className="   group cursor-pointer    pb-4 relative  ">
        <div
          className="flex flex-col gap-2"
          onClick={() => handleProductClick(item)}
        >
          <div className="  h-[200px] sm:h-[450px] overflow-hidden rounded">
          <Image className={`  ${item?.image2 ?' md:group-hover:hidden' :'' } `} src={item.image} alt="" />
            {item?.image2 && (
              <Image
                className={`md:group-hover:flex   hidden `}
                src={item?.image2}
                alt=""
              />
            )}
          </div>
          <div>
            <div className="  flex px-2   gap-1 flex-col">
              <div className=" flex flex-col gap-1">
                <h1 className=" text-white  font-medium text-sm">
                  {item?.name}
                </h1>
                <h1 className=" text-xs   text-secondary-300  font-light">
                  {item?.subtitle}
                </h1>
              </div>
              <h1 className="  text-primary-100 font-medium text-xs">
                <Currency quantity={item.price} currency="inr" />
              </h1>
            </div>
          </div>
        </div>

        <div className=" absolute  right-2 top-2">
          <button
            onClick={() => removeWishlist(item.id)}
            className={`
        flex     rounded-full  overflow-hidden
        p-2    bg-backgound-300 text-secondary-200  `}
          >
            <FaHeart size={16} className="  fill-secondary-100 " />
          </button>
        </div>
      </div>
    </Fragment>
  );
};

const WishlistMain = ({ data, removeWishlist, user }) => {
  const navigate = useNavigate();
  return (
    <div className="bg-secondary-100  py-10  ">
      <div className=" container   px-5 lg:px-16 flex flex-col gap-10 ">
        {user ? (
          <div>
            <h1 className=" text-lg text-white  font-semibold tracking-wide">
              YOUR WISHLIST{" "}
              <span className=" text-sm text-secondary-300">
                {data?.length} Products
              </span>
            </h1>
            {data.length > 0 ? (
              <div className=" grid grid-cols-2  py-10 lg:grid-cols-3 xl:grid-cols-4 gap-3">
                {data?.map((item, index) => (
                  <Cards
                  navigate={navigate}
                    removeWishlist={removeWishlist}
                    item={item}
                    index={index}
                  />
                ))}
              </div>
            ) : (
              <div>
                <EmptyWishlist         navigate={navigate} />
              </div>
            )}
          </div>
        ) : (
          <div className=" flex justify-center">

          <div className="w-[500px]  py-28  flex justify-center flex-col gap-5 ">
            <div className=" flex gap-2 items-center justify-center">
              <FaHeart size={20} color="white" />
              <h1 className=" text-white font-semibold text-lg ">
                SAVE TO WISHLIST
              </h1>
            </div>
            <p className=" text-white text-center  font-extralight text-sm">
              Ever wish you could save all your fave fits & accessories in one
              place to come back to later? Almost like a ✨ wishlist ✨.
            </p>

            <div className="  flex  flex-col gap-2 md:flex-row justify-center   md:gap-5">
              <Button2
                onClick={() => navigate("/login", { state: { login: 2  } })}
                className={
                  " text-lg py-3 md:py-3 uppercase  bg-white text-secondary-100  hover:bg-secondary-300  hover:text-white "
                }
              >
                Create Account
              </Button2>
              <Button
                background={"bg-gray-700"}
                onClick={() => navigate("/login", { state: { login: 1 } })}
                className={" text-lg py-3 md:py-3 uppercase bg-gray-700   hover:bg-secondary-300  px-20"}
              >
                Login
              </Button>
            </div>
          </div>
          </div>

        )}
      </div>
    </div>
  );
};

export default WishlistMain;
