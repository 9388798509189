import LoginMain from "components/Login/LoginMain";
import React, { Fragment } from "react";
import { useLocation } from "react-router-dom";

const Login = () => {
  const location = useLocation();
  const option = location.state?.login;
  const productId = location.state?.id;
  const productName = location.state?.name;
  const cart = location.state?.cart;


  


  return (
    <Fragment>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title> Account - OnePercentClub </title>
      <LoginMain
      cart={cart}
      productId={productId}
      productName={productName}
      option={option} />
    </Fragment>
  );
};

export default Login;
