import { auth, db } from "Firestore/Firebase";
import { useUser } from "UserProvider/UserProvider";
import AccountMain from "components/Account/AccountMain";
import Layout from "components/Layout/Layout";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
const Account = () => {
  const navigation = useNavigate();
  const [user] = useAuthState(auth);
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const docRef = doc(db, "users", user?.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setUserDetails(docSnap.data());
          setLoading(false);
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      } finally {
        // setLoading(false);
      }
    };

    if (user) {
      fetchData();
    } else {
      // setLoading(false);
    }
  }, [user]);

  const [mainAddress, setMainAddress] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      if (userDetails && userDetails.mainaddress) {
        const docRef = doc(
          db,
          "users",
          user?.uid,
          "address",
          userDetails?.mainaddress
        );

        const unsubscribe = onSnapshot(
          docRef,
          (docSnap) => {
            if (docSnap.exists()) {
              setMainAddress(docSnap.data());
              // setLoading(false);
            } else {
              console.log("No such document!");
            }
          },
          (error) => {
            console.error("Error fetching document:", error);
          }
        );

        return () => unsubscribe(); // Cleanup the listener on component unmount
      } else {
        console.log("User details or main address is missing");
        // setLoading(false);
      }
    };

    if (userDetails?.mainaddress) {
      fetchData();
    } else {
    }
  }, [user, userDetails]);

  const [orders, setOrders] = useState(null);

  useEffect(() => {
    if (!user?.uid) {
      return;
    }

    const q = query(
      // collection(db, "users", user.uid, "orders"),
      collection(db, "orders"),
      orderBy("date", "desc")
    );

    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        const ordersArray = [];
        querySnapshot.forEach((doc) => {
          ordersArray.push({ id: doc.id, ...doc.data() });
        });

        const filterData = ordersArray.filter((item) => item.uid === user.uid);

        setOrders(filterData);
        // setOrders(ordersArray);

        setLoading(false);
      },
      (error) => {
        console.error("Error fetching orders: ", error);
      }
    );

    return () => unsubscribe();
  }, [user, userDetails]);

  return (
    <Layout loading={loading} title={"Account"}>
      <AccountMain
        user={user}
        orders={orders}
        mainAddress={mainAddress}
        userDetails={userDetails}
      />
    </Layout>
  );
};

export default Account;
