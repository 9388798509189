import { db } from "Firestore/Firebase";
import Image from "components/Layout/Fields/Image";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import React, { Fragment } from "react";
import Currency from "react-currency-formatter";
import toast from "react-hot-toast";
import { FaHeart } from "react-icons/fa6";
import { FiHeart } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

const ProductsCards = ({ item, openModal, user, wishlist }) => {
  const navigate = useNavigate();
  const data = item;
  // const wishCheck = wishlist && user
  //   ? wishlist?.some((item) => item?.id === data?.id)
  //   : "false";
  //   console.log(wishCheck)
  const wishCheck =
    Array.isArray(wishlist) && wishlist.some((item) => item.id === data.id);

  const addWishlist = async (item) => {
    try {
      const docRef = doc(
        collection(db, "users", user?.uid, "wishlist"),
        item.id
      );

      await setDoc(docRef, {
        id: item.id,
        name: item.name,
        createdAt: serverTimestamp(),
      });

      toast.success("Added Successfully", {
        style: {
          background: "rgba(25,25,25)",
          color: "white",
          fontSize: "13px",
          textTransform: "uppercase",
        },
      });
    } catch (error) {
      console.error("Error adding document: ", error);
      toast.error(error.message, {
        style: {
          background: "rgba(25,25,25)",
          color: "white",
          fontSize: "13px",
          textTransform: "uppercase",
        },
      });
    }
  };

  const removeWishlist = async (id) => {
    try {
      await deleteDoc(doc(db, "users", user?.uid, "wishlist", id));
      toast.success("Removed Successfully", {
        style: {
          background: "rgba(25,25,25)",
          color: "white",
          fontSize: "13px",
          textTransform: "uppercase",
        },
      });
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
  };

  const Icon = wishCheck ? FaHeart : FiHeart;

  const handleProductClick = (item) => {
    navigate(`/products/${item.category}/${item.id}`);
  };


  return (
    <Fragment>
      <div className="  group   pb-4 relative  ">
        <div
        onClick={() => handleProductClick(item)}
          className="flex   flex-col gap-2 cursor-pointer "
        >
          <div className="   h-[200px] sm:h-[450px] relative overflow-hidden rounded">
            <Image className={`  ${item?.image2 ?' md:group-hover:hidden' :'' } `} src={item.image} alt="" />
            {item?.image2 && (
              <Image
                className={` md:group-hover:flex   hidden `}
                src={item?.image2}
                alt=""
              />
            )}
          </div>
          <div>
            <div className="  flex px-2  w-fit  gap-1 flex-col">
              <div className=" flex flex-col gap-1">
                <h1 className=" text-white  font-medium text-sm">
                  {item.name}
                </h1>
                <h1 className=" text-xs   text-secondary-300  font-light">
                  {item.subtitle}
                </h1>
              </div>
              <h1 className="  text-primary-100 font-medium text-xs">
                <Currency quantity={item.price} currency="inr" />
              </h1>
            </div>
          </div>
        </div>

        <div className=" absolute  right-2 top-2">
          <button
            // onClick={openModal}

            onClick={() =>
              user
                ? wishCheck
                  ? removeWishlist(item.id)
                  : addWishlist(item)
                : openModal(item)
            }
            className={`
            flex     rounded-full  overflow-hidden
            p-2    bg-backgound-300 text-secondary-200  `}
          >
            <Icon size={16} color="black" />
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default ProductsCards;
