import React, { useState } from "react";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
const ZoomImage = ({ src }) => {
  const [loaded, setLoaded] = useState(false);
  function onLoad() {
    console.log("loaded");
    setLoaded(true);
  }
  return (
    <>
      <InnerImageZoom
        onLoad={onLoad}
        className={`   ${loaded ? "" : " hidden "}   `}
        src={src}
        zoomSrc={src}
      />

      {!loaded && (
        <SkeletonTheme baseColor="#202020" highlightColor="#444">
          <Skeleton className=" h-full w-full" />
        </SkeletonTheme>
      )}
    </>
  );
};

export default ZoomImage;
