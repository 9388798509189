import React, { Fragment } from "react";
import MainPoster from "../MainPoster";
import ProductsSection from "../ProductsSection";
import ProductPoster from "../ProductPoster";

const AccessoriesMain = ({data , mainPoster , poster}) => {



  return (
    <Fragment>
      <div className="bg-secondary-100  flex  flex-col gap-10 pb-20 ">

        {mainPoster &&
        
        <MainPoster
          className={`h-[200px] md:h-[500px]`}
 
          mainPoster={mainPoster}
        />
        }


       <div className=" md:pt-10">
          <div className=" container   px-5 lg:px-16 flex flex-col gap-10 ">
            <ProductsSection
              data={data}
              title={"ALL ACCESSORIES"}
              title2={""}
            />
          </div>
        </div>
{poster &&
<ProductPoster
  className={`h-[400px] md:h-[600px]`}
  poster={poster}

/>


}
        
      </div>
    </Fragment>
  );
};

export default AccessoriesMain;
