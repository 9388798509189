import Layout from "components/Layout/Layout";
import PolicyMain from "components/Policy/PolicyMain";
import React from "react";

const Terms = () => {
  const data = [
    {
      id: 1,
      title: "TERMS AND CONDITIONS",
      description: ` 
      The website , https://onepercentclubaw.com is owned and operated by One Percent Club AW  India Pvt Ltd and its affiliates/ Group Companies. The trade name, the word mark and all intellectual property rights associated with One Percent Club AW  and https://onepercentclubaw.com are owned solely by One Percent Club AW  SE and or its Group/ Affiliate Companies. ( Hereinafter collectively referred as One Percent Club AW ). 

      \n All orders shall be subject to terms and conditions as mentioned below. Any person placing an order/s on the website ordering the product ( “ customer”) shall be capable of entering into this contract ( competent Parties), their consent being free ( without any force or coercion) and the object and consideration being for a lawful purpose.
      
      All customer / users of this web site hereby understand and agree that their use and/ or placement of any product on the website constitutes the acceptance of the terms and conditions set forth herein in totality and in the event such terms and conditions are not acceptable, the customer/ or user shall not place an order. The Customer/ User confirms and acknowledges that usage of this website is an acceptance of the T & C of this website and is also a representation by the user that he/ She is capable of entering into this Agreement, their consent is free , the object of the product being ordered and consideration being paid is for a lawful purpose.
\n
One Percent Club AW, reserve the right to modify the terms and conditions at any time without prior notice.
Any person placing an order/s on the website The customer ordering the product ( “ customer”) shall be capable of entering into this contract ( competent Parties), their consent being free ( without any force or coercion) and the object and consideration being for a lawful purpose.
\n
The Product should be delivered only in India and can be ordered only by persons residing in India and having a valid address. The delivery shall be subject to the Customer complying with all the legal requirements, rules, regulation and having all statutory documents in place ( whether local or national). PSIPL or any third party courier agency shall not be responsible for any delivery not being done because of the non-existence or the non-production of the statutory documents or the address being incomplete or incorrect.
\n
Once a product has been ordered, any further deviations can happen only before 5 days of the proposed delivery. Deviations from or subsequent amendments or additions to such order must be in writing. Any order or deviation by Phone shall be possible only if the same is followed by a written confirmation.
\n
Subject to raising of a proper order the product shall be delivered at the address indicated by a third party courier agency. The customer herein gives an absolute authorization in favour of such third party agency to deliver the product ordered. As the products are to be delivered by a third party courier Agency, PSIPL shall not be responsible for any late delivery or any non-delivery because of the in correct address.
\n
One Percent Club AW shall have a right to share the information of the customer with such third party in order for the fulfillment of the order placed on the website. The Customer/ user confirms and acknowledges the same. The customer also gives the right to One Percent Club AW use his / her information for sharing marketing and product update . One Percent Club AW Sports India Pvt Ltd shall be the custodian or eh owner of such data.
\n
The title of the products shall be transferred to the Customer once the products has been dispatched. ( check)

\n
The taxes on the product shall be borne by the Customer.
      `,
    },
    {
        id: 2,
        title: "Acceptance of Terms and Conditions :",
        description: ` 
        Display of the product by PSIPL on this website shall deemed to be proposal by and once the u Customer to order any product on the website, it shall be a deemed acceptance of the contract by such user. \n

        Use of this web site and order made in pursuance of the same is user's acceptance of the terms and conditions contained herein. All users of this web site hereby understand and agree that their use of such web site constitutes acceptance of the terms and conditions set forth here. \n
        
        PSIPL, and the One Percent Club AW group of companies, ("), reserve the right to modify the contents and terms and conditions being displayed on the website any time without prior notice. If you do not agree to these terms, you shall not use this web site. \n
        `,
      },
      {
        id: 3,
        title: "Trademarks :",
        description: ` 
        This web site contains many trademarks, trade names, service marks, copyrights, and/or logos of PSIPL and or is Group Companies and also contain several protected marks of PSIPL numerous affiliates. Such marks remain the property of their respective owners. The customer recognizes and acknowledges the ownership of these marks and understands that it does not acquire, through use of this web site, any right, title, or interest in the marks. The customer agrees that it will not change, modify, and/or exploit the marks, nor participate in any activity which modifies and/or exploits such marks. All rights to these marks are reserved. Any use of the marks without expressed written authorization is strictly prohibited.
        `,
      },
      {
        id: 4,
        title: "No Additional Guarantee/ warranty and customer Dispute :",
        description: ` 
        PSIPL does not give any additional warranties or guarantee except for the same being mentioned on the site

        The customer agrees to indemnify PSIPL and its affiliate companies for any breach and/ or misrepresentation made herein.
        
        Any customer complaint between the customers with respect to the delivery of the product shall be between such customer and the legal entity who has delivered the product without any liability of any nature whatsoever being passed on PSIPL and its affiliate companies. However PSIPL shall be responsible for the quality of the product , subject to there being no limitation.
        `,
      },
      {
        id: 5,
        title: "Prohibited Use:",
        description: ` 
        Any use of this web site for an illegal or objectionable purpose is strictly prohibited. The customer agrees that it/he/she will not use this web site to engage in any activity that could be deemed illegal, harmful to others, or give rise to civil liability. Such activities include, but are not limited to: (i) activities involving the transmission of unlawful, threatening, harassing, obscene, sexually explicit, pornographic, hateful, profane, libelous, or defamatory information; (ii) activities involving the transmission of junk mail or spamming; (iii) activities involving the promotion or use of viruses; (iv) activities that violate any law, regulation or statute; and/or (v) activities that infringe upon any legally protected property right, etc. \n
 
        By using this site, the Customer agrees that any and all information transmitted to or with the use of this site cannot and shall not be deemed confidential or proprietary. PSIPL along with its group/ affiliate companies (“One Percent Club AW”) reserves the right to monitor transmissions and investigate any alleged prohibited use of this web site and to disclose any and all information relating to such prohibited use. One Percent Club AW, its officers, directors, affiliates, employees, agents, partners, subsidiaries, and/or contractors shall not assume, and expressly disclaim, any and all liability relating to an individual's illegal or prohibited use of this web site. Any violation of this or any other section contained herein may result in termination of service and or any other action One Percent Club AW determines appropriate under the circumstances.
        `,
      },
      {
        id: 6,
        title: "Third-Party Relationships:",
        description: ` 
        This web site may contain links to other sites. These links are provided merely to assist the Customer. These sites are independent of One Percent Club AW's site, and One Percent Club AW does not and cannot control the content and/or representations of such sites. The information presented via these links may not necessarily reflect those beliefs held by One Percent Club AW. The inclusion of a link does not mean that One Percent Club AW accepts or endorses any of the content contained in such site. The user is responsible to protect himself / herself while associating with the linked site. One Percent Club AW, and its officers, directors, affiliates, employees, agents, partners, subsidiaries and/or contractors disclaim any and all responsibility or liability for content contained on such linked sites and will not be held accountable for any damages, of any kind, incurred by those who visit such sites.
        `,
      },
      {
        id: 7,
        title: "Governing Law:",
        description: ` 
        These terms shall be governed by and constructed in accordance with the laws of India and disputes arising in relation hereto shall be subject to the exclusive jurisdiction of the courts at Kolkata.
        `,
      },
  ];
  
  return (
    <Layout title={"Terms and Conditions"}>
      <PolicyMain title={"Terms and Conditions"} data={data} />
    </Layout>
  );
};

export default Terms;
