import React, { Fragment, useEffect, useState } from "react";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import Detail from "./Detail";
import { useNavigate } from "react-router-dom";
import Address from "./Address";
import ModalSection from "components/Layout/ModalSection";
import { HiMiniXMark } from "react-icons/hi2";
import Select from "components/Layout/Fields/Select";
import InputText from "components/Layout/Fields/InputText";
import States from "Data/States";
import Button3 from "components/Layout/Fields/Button3";
import Button5 from "components/Layout/Fields/Button5";
import Button2 from "components/Layout/Fields/Button2";
import toast from "react-hot-toast";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { db } from "Firestore/Firebase";
import Button from "components/Layout/Fields/Button";

const AddressMain = ({ user, userDetails = {}, mainAddress, addressList }) => {
  const { name, email } = userDetails || "";
  const navigation = useNavigate();
  const initialValue = {
    email: "",
    phone: "",
    name: "",
    lastname: "",
    pincode: "",
    street: "",
    address: "",
    city: "",
    state: "AP",
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedState, setSelectedState] = useState("AP");
  const [errors, setErrors] = useState("");
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(initialValue);
  const [selected, setSelected] = useState(null);

  const openModal = () => {
    setIsModalOpen(!isModalOpen);
    if (!selected) {
      setFormData((prevState) => ({
        ...prevState,
        email: email,
      }));
    }
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setFormData(initialValue);
    setErrors();
    setSelected(null);
    setLoading(false);
  };
  const editAddress = (item) => {
    setSelected(item.id);
    openModal();
    setFormData((prevState) => ({
      ...prevState,
      email: item.email || "",
      phone: item.phone || "",
      name: item.firstname || "",
      lastname: item.lastname || "",
      pincode: item.pincode || "",
      street: item.street || "",
      address: item.address || "",
      city: item.city || "",
      state: item.state || "",
    }));
  };
  const updateAddress = async (e) => {
    if (selected !== null) {
      e.preventDefault();

      setLoading(true);
      await updateDoc(doc(db, "users", user?.uid, "address", selected), {
        email: formData.email,
        phone: formData.phone,
        firstname: formData.name,
        lastname: formData.lastname,
        pincode: formData.pincode,
        street: formData.street,
        address: formData.address,
        state: formData.state,
        name: formData.name,
        city: formData.city,
      }).then(() => {
        setLoading(false);
        toast.success("Address Added Successfully", {
          style: {
            background: "rgba(25,25,25)",
            color: "white",
            fontSize: "13px",
            textTransform: "uppercase",
          },
        });
        if (mainAddress.id === selected) {
          const washingtonRef = doc(db, "users", user?.uid);
          updateDoc(washingtonRef, {
            phone: formData.phone || "",
          }).then(() => {
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          });
        }

        closeModal();
      });
    }
  };

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      email: email,
    }));
  }, [email]);

  const validate = () => {
    let tempErrors = {};
    if (!formData?.email) tempErrors.email = "Email is required";
    if (!formData?.phone) tempErrors.phone = "Phone is required";
    if (!formData?.name) tempErrors.name = "First Name is required";
    if (!formData?.lastname) tempErrors.lastname = "Last Name is required";
    if (!formData?.pincode) tempErrors.pincode = "Pincode is required";
    if (!formData?.street) tempErrors.street = "Street is required";
    if (!formData?.city) tempErrors.city = "City is required";
    if (!formData?.state) tempErrors.state = "State is required";
    if (!formData?.address) tempErrors.address = "Address is required";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const addAddress = async (e) => {
    e.preventDefault();

    if (validate()) {
      setLoading(true);
      try {
        const addressData = {
          email: formData.email,
          phone: formData.phone,
          firstname: formData.name,
          lastname: formData.lastname,
          pincode: formData.pincode,
          street: formData.street,
          address: formData.address,

          state: formData.state,
          name: formData.name,
          city: formData.city,
          createdAt: serverTimestamp(),
        };

        const docRef = await addDoc(
          collection(db, "users", user?.uid, "address"),
          addressData
        );

        await updateDoc(doc(db, "users", user?.uid, "address", docRef.id), {
          id: docRef.id,
        });

        const userDocRef = doc(db, "users", user?.uid);
        await updateDoc(userDocRef, {
          mainaddress: docRef?.id,
          phone: formData.phone,
        });

        window.location.reload();

        toast.success("Address Added Successfully", {
          style: {
            background: "rgba(25,25,25)",
            color: "white",
            fontSize: "13px",
            textTransform: "uppercase",
          },
        });
        setLoading(false);
        closeModal();
        setFormData(initialValue);
      } catch (error) {
        console.error("Error adding address: ", error);
        toast.error("Failed to add address", {
          style: {
            background: "rgba(25,25,25)",
            color: "white",
            fontSize: "13px",
            textTransform: "uppercase",
          },
        });
      }
    } else {
      toast.error("Please fill all fields", {
        style: {
          background: "rgba(25,25,25)",
          color: "white",
          fontSize: "13px",
          textTransform: "uppercase",
        },
      });
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const updateMainAddress = (id, phone) => {
    const washingtonRef = doc(db, "users", user?.uid);
    updateDoc(washingtonRef, {
      mainaddress: id,
      phone: phone || "",
    }).then(() => {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    });
    toast.success("Main address updated", {
      style: {
        background: "rgba(25,25,25)",
        color: "white",
        fontSize: "13px",
        textTransform: "uppercase",
      },
    });
  };
  const deleteAddress = async (id) => {
    try {
      await deleteDoc(doc(db, "users", user?.uid, "address", id));
      // console.log(`Document with ID ${docId} deleted successfully`);

      setTimeout(() => {
        window.location.reload();
      }, 2000);

      toast.success("Address Delete Successfully", {
        style: {
          background: "rgba(25,25,25)",
          color: "white",
          fontSize: "13px",
          textTransform: "uppercase",
        },
      });
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
  };

  return (
    <Fragment>
      <div className="bg-secondary-100  py-10  ">
        <div className=" container   px-5 xl:px-16 flex flex-col gap-14 pb-20 ">
          <div className=" flex   flex-col-reverse md:flex-row gap-3 md:gap-0  md:justify-center md:items-center   relative">
            <div
              onClick={() => navigation("/account")}
              className=" flex  left-0 md:absolute items-center cursor-pointer gap-2"
            >
              <MdOutlineArrowBackIosNew color="white" />
              <h1 className=" text-sm underline text-white">Back to Account</h1>
            </div>

            <h1 className=" md:text-center font-semibold  text-lg md:text-2xl text-white tracking-wide">
              ADDRESS BOOK
            </h1>
          </div>
          <div className=" flex   gap-10  xl:gap-0 flex-col lg:flex-row justify-between">
            <div className="  lg:sticky  h-fit top-20   lg:w-[30%] xl:w-[27%]">
              <Detail
                mainAddress={mainAddress}
                openModal={openModal}
                email={email}
                name={name}
              />
            </div>
            <div className=" lg:w-[70%]">
              <Address
                editAddress={editAddress}
                mainAddress={mainAddress}
                deleteAddress={deleteAddress}
                updateMainAddress={updateMainAddress}
                userDetails={userDetails}
                addressList={addressList}
              />
            </div>
          </div>
        </div>
      </div>
      <ModalSection
        isOpen={isModalOpen}
        onClose={closeModal}
        className="  lg:w-[600px]"
      >
        <Fragment>
          <div className=" px-0 pt-3 pb-10 md:p-3 flex flex-col gap-4 ">
            <div className=" flex justify-between items-center">
              <h1 className=" text-secondary-100 text-lg  tracking-wider font-medium">
                {selected ? "Update Address" : "Add Address"}
              </h1>
              <button onClick={closeModal}>
                <HiMiniXMark size={25} />
              </button>
            </div>

            <form
              onSubmit={selected ? updateAddress : addAddress}
              action=""
              className="  flex flex-col gap-2"
            >
              <div className=" flex flex-col gap-5">
                <div className=" flex flex-col gap-5">
                  {!selected && (
                    <h1 className=" text-lg text-secondary-100 tracking-wider   font-normal uppercase">
                      Enter Contact Info
                    </h1>
                  )}

                  <div className=" md:col-span-2  flex flex-col  md:grid grid-cols-2 gap-5">
                    <div className="   ">
                      <InputText
                        label={"EMAIL "}
                        required={true}
                        value={formData?.email}
                        name="email"
                        onChange={handleChange}
                        type={"email"}
                        className=""
                        placeholder={"Email"}
                        color={"  text-secondary-100"}
                        error={errors?.email}
                      />
                    </div>
                    <div className="   ">
                      <InputText
                        label={"PHONE NUMBER"}
                        required={true}
                        value={formData?.phone}
                        name="phone"
                        onChange={handleChange}
                        type={"number"}
                        className=""
                        placeholder={"Phone Number"}
                        color={"  text-secondary-100"}
                        error={errors?.phone}
                      />
                    </div>
                  </div>
                </div>

                <div className=" col-span-2  flex flex-col   gap-5">

                <div className=" col-span-2   flex flex-col  md:grid grid-cols-2 gap-5">

                  <div className=" ">
                    <InputText
                      label={"FIRST NAME "}
                      required={true}
                      value={formData.name}
                      name="name"
                      onChange={handleChange}
                      type={"text"}
                      className=""
                      placeholder={"First Name"}
                      error={errors?.name}
                      color={"  text-secondary-100"}
                    />
                  </div>
                  <div>
                    <InputText
                      label={"LAST NAME "}
                      required={true}
                      value={formData?.lastname}
                      name="lastname"
                      onChange={handleChange}
                      type={"text"}
                      className=""
                      placeholder={"Last Name"}
                      color={"  text-secondary-100"}
                      error={errors?.lastname}
                    />
                  </div>
                  </div>



                  <div className="">
                    <InputText
                      label={"PIN CODE "}
                      required={true}
                      value={formData?.pincode}
                      name="pincode"
                      onChange={handleChange}
                      type={"number"}
                      className=""
                      placeholder={"PIN code"}
                      color={"  text-secondary-100"}
                      error={errors?.pincode}
                    />
                  </div>
                  <div className="   ">
                    <InputText
                      label={"STREET "}
                      required={true}
                      value={formData?.street}
                      name="street"
                      onChange={handleChange}
                      type={"text"}
                      className=""
                      placeholder={"Street"}
                      color={"  text-secondary-100"}
                      error={errors?.street}
                    />
                  </div>
                  <div className="   col-span-2">
                    <InputText
                      label={"ADDRESS"}
                      required={true}
                      value={formData?.address}
                      name="address"
                      onChange={handleChange}
                      type={"text"}
                      className=""
                      placeholder={"Address"}
                      color={"  text-secondary-100"}
                      error={errors?.address}
                    />
                  </div>

                  <div className=" col-span-2   flex flex-col  md:grid grid-cols-2 gap-5">
                    <div className="   ">
                      <InputText
                        color={"  text-secondary-100"}
                        label={"CITY "}
                        required={true}
                        value={formData?.city}
                        name="city"
                        onChange={handleChange}
                        type={"text"}
                        className=""
                        placeholder={"City"}
                        error={errors?.city}
                      />
                    </div>
                    <div className=" w-full   ">
                      <Select
                        color={"  text-secondary-100"}
                        required={true}
                        label={"STATE"}
                        id="qty"
                        value={formData?.state}
                        name="state"
                        onChange={handleChange}
                        options={States}
                        className=""
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className=" pt-5">
                <div className=" grid    md:grid-cols-2  gap-3 md:gap-5">
                  {/* <Button5
                    disabled={loading}
                    className={
                      "whitespace-pre md:px-5  py-3 lg:px-10  text-sm  bg-secondary-100  border  hover:bg-secondary-300 uppercase     text-white flex items-center gap-2  hover:border-secondary-100 "
                    }
                  >
                    {selected ? "Update" : "Save"}
                  </Button5> */}

                  <Button className={""} disabled={loading}>
                    {selected ? "Update" : "Save"}
                  </Button>

                  <Button
                    background={"bg-gray-700"}
                    onClick={closeModal}
                    disabled={false}
                    className={
                      " text-lg py-3 md:py-3 uppercase bg-gray-700   hover:bg-secondary-300  px-20"
                    }
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </Fragment>
      </ModalSection>
    </Fragment>
  );
};

export default AddressMain;
