import React from "react";
import Detail from "./Detail";
import Orders from "./Orders";

const AccountMain = ({ userDetails , mainAddress , orders , user }) => {
  return (
    <div className="bg-secondary-100  py-10  ">
      <div className=" container   px-5 xl:px-16 flex flex-col gap-14 pb-20 ">
        <div>
          <h1 className=" text-center font-semibold  text-lg md:text-2xl text-white tracking-wide">
            YOUR ONE PERCENT CLUB ACCOUNT
          </h1>
        </div>
        <div className=" flex   gap-10 xl:gap-0 flex-col lg:flex-row justify-between">
          <div className="  lg:sticky  h-fit top-20 lg:w-[30%] xl:w-[27%]">
            <Detail mainAddress={mainAddress} userDetails={userDetails} />
          </div>
          <div className=" lg:w-[70%]">
            <Orders user={user} data={orders} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountMain;
