import React from "react";
import Logo from "assets/images/logo.png";
import Image from "./Fields/Image";
const Loader = ({loading}) => {
  return (
    <div
    style={{ opacity: loading ? 1 : 0, zIndex: loading ? 9999 : -1 }}
    
    className=" bg-secondary-100   top-0 fixed w-full h-full   flex justify-center  items-center">
      <div className=" w-24     h-16">
        <img className=" w-full h-full" src={Logo} />
      </div>
      
    </div>
  );
};

export default Loader;
