import { auth, db } from "Firestore/Firebase";
import Layout from "components/Layout/Layout";
import ViewMain from "components/ProductView/ViewMain";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const ProductView = () => {
  const location = useLocation();
  const { category, id } = useParams();
  const navigate = useNavigate();

  const pathname = location?.pathname;
  const updatedPathname = pathname ? pathname.split('%')[0] : pathname;



  useEffect(() => {
    navigate(updatedPathname, { replace: true });
  }, [updatedPathname, category, id, navigate]);


  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState(null);
  const [gallery, setGallery] = useState([]);
  const [size, setSize] = useState([]);
  const [related, setRelated] = useState([]);
  const [colors, setColors] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const docRef = doc(db, "products", category, "products", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setProduct(docSnap.data());
          setLoading(false);
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };

    fetchData();
  }, [id, category]);

  useEffect(() => {
    try {
      const q = query(
        collection(db, "products", category, "products", id, "images"),
        orderBy("createdAt", "asc")
      );
      onSnapshot(q, (querySnapshot) => {
        const cities = [];
        querySnapshot.forEach((doc) => {
          cities.push({ id: doc.id, ...doc.data() });
        });

        const filteredCities = cities.filter((item) => item.available === true);

        setGallery(filteredCities);
        // setLoading(false)
      });
    } catch {}
  }, [id, category]);
  useEffect(() => {
    try {
      const q = query(
        collection(db, "products", category, "products", id, "size"),
        orderBy("number", "asc")
      );
      onSnapshot(q, (querySnapshot) => {
        const cities = [];
        querySnapshot.forEach((doc) => {
          cities.push({ id: doc.id, ...doc.data() });
        });
        const filteredCities = cities.filter((item) => item.available === true);

        setSize(filteredCities);
      });
    } catch {}
  }, [id, category]);

  useEffect(() => {
    try {
      const q = query(
        collection(db, "products", category, "products"),
        orderBy("createdAt", "desc")
      );
      onSnapshot(q, (querySnapshot) => {
        const cities = [];
        querySnapshot.forEach((doc) => {
          cities.push({ id: doc.id, ...doc.data() });
        });
        const filteredCities = cities.filter(
          (item) => item.id !== id && item.available === true
        );

        const filteredColors = cities.filter(
          (item) =>
            item.id !== id &&
            item?.name === product?.name &&
            item.available === true
        );

        setColors(filteredColors);
        setRelated(filteredCities);
        // setLoading(false);
      });
    } catch {}
  }, [category, id, product]);

  const [user] = useAuthState(auth);

  const [posters, setPosters] = useState([]);
  const [mainPoster, setMainPoster] = useState([]);
  const [poster, setPoster] = useState([]);

  useEffect(() => {
    try {
      const q = query(
        collection(db, "products", category, "posters"),
        orderBy("number", "asc")
      );
      onSnapshot(q, (querySnapshot) => {
        const cities = [];
        querySnapshot.forEach((doc) => {
          cities.push({ id: doc.id, ...doc.data() });
        });

        const filteredCities = cities.filter((item) => item.available === true);
        setMainPoster(filteredCities[0]);
        setPoster(filteredCities[1]);
        setPosters(filteredCities);
      });
    } catch {}
  }, []);

  return (
    <Layout loading={loading} title={"Products"}>
      <ViewMain
        mainPoster={mainPoster}
        poster={poster}
        user={user}
        colors={colors}
        product={product}
        gallery={gallery}
        size={size}
        related={related}
      />
    </Layout>
  );
};

export default ProductView;
