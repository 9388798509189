import React, { Fragment } from "react";
import Button from "components/Layout/Fields/Button";
import Image from "components/Layout/Fields/Image";
import ModalSection from "components/Layout/ModalSection";
import { FiHeart } from "react-icons/fi";
import { HiMiniXMark } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import Button3 from "components/Layout/Fields/Button3";
const BeforeModal = ({ isModalOpen, closeModal, select }) => {
  const navigate = useNavigate();
  return (
    <ModalSection
      isOpen={isModalOpen}
      onClose={closeModal}
      padding={"p-0"}
      className="  w-[400px] md:min-w-[700px]   "
    >
      <Fragment>
        <div>
          <div className=" relative flex justify-center p-4 ">
            <div className=" flex gap-2 items-center justify-center">
              <FiHeart size={20} className="  fill-secondary-100 " />
              <h1 className=" font-semibold text-lg ">SAVE TO WISHLIST</h1>
            </div>
            <div className=" absolute right-5  ">
              <button onClick={closeModal}>
                <HiMiniXMark size={25} />
              </button>
            </div>
          </div>

          <div>
            <Image
              src={
                "https://www.gymshark.com/_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2Fwl6q2in9o7k3%2F7CIbSN8ZiUSQ4m35Kob2Ww%2F7eedb94138b0c04ad6ced69217134e81%2Fwishlist-banner.webp&w=3840&q=85"
              }
            />
          </div>
          <div className=" p-5 flex  flex-col gap-4">
            <div>
              <p className=" text-center text-sm   text-secondary-100 ">
                Ever wish you could save all your fave fits & accessories in one
                place to come back to later? Almost like a ✨ wishlist ✨.
              </p>
            </div>

            <div className="  flex  flex-col gap-2 md:flex-row justify-center   md:gap-5">
              <Button
                onClick={() =>
                  navigate("/login", {
                    state: {
                      login: 2,
                      id: select ? select.id : null,
                      name: select ? select.name : null,
                    },
                  })
                }
                className={
                  " text-lg py-3 md:py-3 uppercase  "
                }
              >
                Create Account
              </Button>
              <Button
                background={"bg-gray-700"}
                onClick={() =>
                  navigate("/login", {
                    state: {
                      login: 1,
                      id: select ? select.id : null,
                      name: select ? select.name : null,
                    },
                  })
                }
                className={
                  " text-lg py-3 md:py-3 uppercase bg-gray-700   hover:bg-secondary-300  px-20"
                }
              >
                Login
              </Button>
            </div>
          </div>
        </div>
      </Fragment>
    </ModalSection>
  );
};

export default BeforeModal;
