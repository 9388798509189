import React, { Fragment, useEffect, useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Loader from "./Loader";
import { useUser } from "UserProvider/UserProvider";
import { auth, db } from "Firestore/Firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { collection, onSnapshot, query } from "firebase/firestore";
import { selectBasketItems } from "ReduxSlice/BasketSlice";
import { useSelector } from "react-redux";


const Layout = ({ title, children , loading }) => {
  const [user] = useAuthState(auth);

  const cartData = useSelector(selectBasketItems);


  const [wishlist, setWishlist] = useState(null);
  const [data, setData] = useState([]);


useEffect(() => {
  if (user) {
    try {
      const menQuery = query(collection(db, "products", "men", "products"));
      const womenQuery = query(collection(db, "products", "women", "products"));
      const accessoriesQuery = query(collection(db, "products", "accessories", "products"));

      Promise.all([
        new Promise((resolve) => {
          onSnapshot(menQuery, (querySnapshot) => {
            const menProducts = [];
            querySnapshot.forEach((doc) => {
              menProducts.push({ id: doc.id, ...doc.data() });
            });
            resolve(menProducts);
          });
        }),
        new Promise((resolve) => {
          onSnapshot(womenQuery, (querySnapshot) => {
            const womenProducts = [];
            querySnapshot.forEach((doc) => {
              womenProducts.push({ id: doc.id, ...doc.data() });
            });
            resolve(womenProducts);
          });
        }),
        new Promise((resolve) => {
          onSnapshot(accessoriesQuery, (querySnapshot) => {
            const accessoriesProducts = [];
            querySnapshot.forEach((doc) => {
              accessoriesProducts.push({ id: doc.id, ...doc.data() });
            });
            resolve(accessoriesProducts);
          });
        })
      ]).then((results) => {
        const combinedProducts = results.flat();
        const filteredProducts = combinedProducts.filter((item) => item.available === true);
        setData(filteredProducts);
       
      }).catch(() => {
   
      });

    } catch {
    
    }
  } else {
    
  }
}, [user]);

  useEffect(() => {
    if (user) {
      try {
        const q = query(collection(db, "users", user?.uid, "wishlist"));

        onSnapshot(q, (querySnapshot) => {
          const cities = [];
          querySnapshot.forEach((doc) => {
            cities.push({ id: doc.id, ...doc.data() });
          });
          setWishlist(cities);
        });
      } catch {
        console.log("Error fetching data");
      }
    }
  }, [user]);

  const wishlistIds = wishlist?.map((item) => item.id);
  const wishList = data?.filter((item) => wishlistIds?.includes(item.id));




  return (
    <Fragment>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title> {title} - OnePercentClub AW</title>
      <Navbar cartData={cartData}  wishCount={wishList?.length} wishlist={wishlist} user={user} />
      <Loader loading={loading}/>
      {children}
      <Footer/>
    </Fragment>
  );
};

export default Layout;
