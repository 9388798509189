import Image from "components/Layout/Fields/Image";
import React, { Fragment, useEffect, useState } from "react";
import Logo from "assets/images/logo.png";
import InputText from "components/Layout/Fields/InputText";
import Button2 from "components/Layout/Fields/Button2";
import ForgotModal from "./ForgotModal";
import toast from "react-hot-toast";
import {
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import {
  collection,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { auth, database, db } from "Firestore/Firebase";
import { useNavigate } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
const Poster = () => {
  const promotion = [
    {
      id: 1,
      title: "TRACK YOUR ORDERS",
      title2: "Keep track the status of your orders",
    },
    {
      id: 2,
      title: "SHOP YOUR WAY",
      title2:
        "Discover the latest launches and be the first to get notifications for new drops",
    },
    {
      id: 3,
      title: "SAVE WHAT YOU SEE",
      title2:
        "Save your most-loved activewear pieces to build your perfect outfit",
    },
    {
      id: 4,
      title: "FREE ONE PERCENT CLUB AW TRAIN ACCESS",
      title2:
        "Save your most-loved activewear pieces to build your perfect outfit",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === promotion?.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000);
    return () => clearInterval(interval);
  }, [currentIndex?.length]);

  return (
    <div className="  relative h-[100vh]">
      <div className="  h-[100vh]">
        <Image src={"https://cdn.develop.gymshark.com/auth-img.jpg"} />
      </div>

      <div className=" absolute top-0 w-full  flex   items-center   p-16   h-full   ">
        <div className=" flex flex-col gap-4">
          {promotion.map((item, index) => (
            <div
              key={index}
              className={`
               ${
                 currentIndex === index ? "opacity-100" : "opacity-0 hidden"
               }  transition-all delay-75  `}
            >
              <div className=" flex flex-col gap-4">
                <div className=" flex flex-col gap-6">
                  <h1 className=" text-xs      text-secondary-300">
                    One account, across all Apps.
                  </h1>
                  <h1 className="   text-white  text-5xl font-semibold">
                    {item.title}
                  </h1>
                  <p className="  text-white">{item.title2}</p>
                </div>
              </div>
            </div>
          ))}
          <div className="  flex gap-2  ">
            {promotion.map((item, index) => (
              <div
                onClick={() => setCurrentIndex(index)}
                key={index}
                className={` rounded-full cursor-pointer    p-1   hover:bg-white  ${
                  currentIndex === index ? " bg-white" : "  bg-gray-500"
                }`}
              ></div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
const Login = ({ select, selectOption, productId, productName, cart }) => {
  const [email, setEmail] = useState("");
  const [forgotemail, setForgotEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const [errors, setErrors] = useState({
    email: "",
    password: "",
    forgotemail: "",
  });

  const navigate = useNavigate();

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(!isModalOpen);
    setForgotEmail("");
    setErrors({ email: "", password: "", forgotemail: "" });
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setForgotEmail("");
    setErrors({ email: "", password: "", forgotemail: "" });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let valid = true;
    const newErrors = { email: "", password: "" };

    if (!validateEmail(email)) {
      newErrors.email = "Email must be a valid email address";
      valid = false;
    }

    if (password.trim() === "") {
      newErrors.password = "Password is required";
      valid = false;
    }

    setErrors(newErrors);
    if (newErrors.email) {
      toast.error(newErrors.email, {
        style: {
          background: "rgba(25,25,25)",
          color: "white",
          fontSize: "13px",
          textTransform: "uppercase",
        },
      });
    }

    if (newErrors.password) {
      toast.error(newErrors.password, {
        style: {
          background: "rgba(25,25,25)",
          color: "white",
          fontSize: "13px",
          textTransform: "uppercase",
        },
      });
    }

    if (valid) {
      if (select === 1) {
        handleLogin();
      } else {
        register();
      }
    }
  };

  const handleLogin = () => {
    if (!email || !password) {
      toast.error("Please fill all fields", {
        style: {
          background: "rgba(25,25,25)",
          color: "white",
          fontSize: "13px",
          textTransform: "uppercase",
        },
      });
    } else {
      setLoading(true);
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          const user = userCredential.user;
          console.log("User information:", user.uid);

          if (productId) {
            addWishlist(user.uid);
            setTimeout(() => {
              setLoading(false);
              navigate("/wishlist");
            }, 3000);
          } else if (cart === true) {
            setTimeout(() => {
              setLoading(false);
              navigate("/cart");
            }, 3000);
          } else {
            setTimeout(() => {
              setLoading(false);
              navigate("/");
            }, 3000);
          }

          toast.success("Login Successfully", {
            style: {
              background: "rgba(25,25,25)",
              color: "white",
              fontSize: "13px",
              textTransform: "uppercase",
            },
          });
        })
        .catch((error) => {
          console.error("Error during sign-in:", error);
          setLoading(false);
          console.log(error.message.Error);
          toast.error(error.message, {
            style: {
              background: "rgba(25,25,25)",
              color: "white",
              fontSize: "13px",
              textTransform: "uppercase",
            },
          });
        });
    }
  };

  const register = async (e) => {
    if (name && email && password) {
      try {
        setLoading(true);

        const res = await createUserWithEmailAndPassword(auth, email, password);
        const user = res.user;

        await setDoc(doc(db, "users", user.uid), {
          uid: user.uid,
          name: name,
          email: email,
          createdAt: serverTimestamp(),
        });

        setName("");
        setEmail("");
        setPassword("");

        if (productId) {
          addWishlist(user.uid);
          setTimeout(() => {
            setLoading(false);
            navigate("/wishlist");
          }, 3000);
        } else if (cart === true) {
          navigate("/cart");
          setTimeout(() => {
            setLoading(false);
            navigate("/");
          }, 3000);
        } else {
          setTimeout(() => {
            setLoading(false);
            navigate("/");
          }, 3000);
        }

        toast.success("Account Created Successfully", {
          style: {
            background: "rgba(25,25,25)",
            color: "white",
            fontSize: "13px",
            textTransform: "uppercase",
          },
        });
      } catch (err) {
        console.error("Error creating user:", err);
        setLoading(false);

        toast.error(err.message, {
          style: {
            background: "rgba(25,25,25)",
            color: "white",
            fontSize: "13px",
            textTransform: "uppercase",
          },
        });
      }
    } else {
      toast.error("Please fill all fields", {
        style: {
          background: "rgba(25,25,25)",
          color: "white",
          fontSize: "13px",
          textTransform: "uppercase",
        },
      });
    }
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);

      const user = result.user;
      const userDocRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userDocRef);

      if (!userDoc.exists()) {
        await setDoc(doc(db, "users", user.uid), {
          uid: user.uid,
          name: user?.displayName,
          email: user?.email,
          createdAt: serverTimestamp(),
        });
      } else {
        await setDoc(
          userDocRef,
          {
            name: user.displayName || "",
            email: user.email || "",
          },
          { merge: true }
        ); // Merge with existing document
      }

      if (productId) {
        addWishlist(user.uid);
        navigate("/wishlist");
      } else if (cart === true) {
        navigate("/cart");
      } else {
        navigate("/");
      }
    } catch (error) {
      alert(error.message);
    }
    toast.success("Signed in with Google Successfully", {
      style: {
        background: "rgba(25,25,25)",
        color: "white",
        fontSize: "13px",
        textTransform: "uppercase",
      },
    });
  };

  const addWishlist = async (user) => {
    try {
      const docRef = doc(collection(db, "users", user, "wishlist"), productId);

      await setDoc(docRef, {
        id: productId,
        name: productName,
        createdAt: serverTimestamp(),
      });

      toast.success("Added Successfully", {
        style: {
          background: "rgba(25,25,25)",
          color: "white",
          fontSize: "13px",
          textTransform: "uppercase",
        },
      });
    } catch (error) {
      console.error("Error adding document: ", error);
      toast.error(error.message, {
        style: {
          background: "rgba(25,25,25)",
          color: "white",
          fontSize: "13px",
          textTransform: "uppercase",
        },
      });
    }
  };

  const handleReset = async (e) => {
    e.preventDefault();

    if (!forgotemail) {
      toast.error("Please fill all fields", {
        style: {
          background: "rgba(25,25,25)",
          color: "white",
          fontSize: "13px",
          textTransform: "uppercase",
        },
      });
      return;
    }

    setLoading2(true);
    const newErrors = { forgotemail: "" };

    if (!validateEmail(forgotemail)) {
      newErrors.forgotemail = "Email must be a valid email address";
      setErrors(newErrors);
      toast.error(newErrors.forgotemail, {
        style: {
          background: "rgba(25,25,25)",
          color: "white",
          fontSize: "13px",
          textTransform: "uppercase",
        },
      });
      setLoading2(false);
      return;
    }

    setErrors(newErrors);

    try {
      await sendPasswordResetEmail(database, forgotemail);

      toast.success("Check your email", {
        style: {
          background: "rgba(25,25,25)",
          color: "white",
          fontSize: "13px",
          textTransform: "uppercase",
        },
      });

      closeModal();
    } catch (err) {
      toast.error(err.message, {
        style: {
          background: "rgba(25,25,25)",
          color: "white",
          fontSize: "13px",
          textTransform: "uppercase",
        },
      });
    } finally {
      setLoading2(false);
    }
  };

  return (
    <Fragment>
      <div className=" h-[100vh] px-5  ">
        <div className=" flex w-full  h-full py-10 lg:py-0 justify-center lg:items-center">
          <div className=" w-[500px] flex flex-col gap-5">
            <div className=" flex justify-center ">
              <div className="  w-16 h-11 cursor-pointer  ">
                <img className=" w-full h-full " src={Logo} alt="" />
              </div>
            </div>

            <h1 className=" text-center font-semibold text-xl uppercase  tracking-wide  text-white">
              One Percent Club
            </h1>
            <div className=" flex justify-center">
              <div className="bg-secondary-200 p-1 rounded-full">
                <button
                  //   style={{ transition: 'all 0.25s ease-in-out'}}
                  onClick={() => selectOption(1)}
                  className={` ${
                    select === 1
                      ? "bg-white text-secondary-100"
                      : " text-white "
                  } uppercase font-semibold text-sm py-2 px-10 rounded-full`}
                >
                  Log in
                </button>
                <button
                  //    style={{ transition: 'all 0.25s ease-in-out'}}
                  onClick={() => selectOption(2)}
                  className={` ${
                    select === 2 ? "bg-white text-secondary-100" : "text-white "
                  } uppercase font-semibold  text-sm py-2 px-10 rounded-full`}
                >
                  Sign up
                </button>
              </div>
            </div>

            <div
              className={`transition duration-300 ease-in-out ${
                select === 1 ? "opacity-100" : "hidden opacity-0"
              }`}
            >
              <form
                onSubmit={handleSubmit}
                action=""
                className=" flex flex-col gap-5 "
              >
                <div className="   flex flex-col   gap-5">
                  <div className="   ">
                    <InputText
                      label={"EMAIL"}
                      required={true}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      // type={"email"}
                      className=""
                      placeholder={"Email"}
                      error={errors.email}
                    />
                  </div>
                  <div className="   ">
                    <InputText
                      label={"PASSWORD "}
                      error={errors.password}
                      required={true}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      type={"password"}
                      className=""
                      placeholder={"Password"}
                      maxLength={20}
                      showPassword={showPassword}
                      togglePassword={() => setShowPassword(!showPassword)}
                    />
                  </div>
                </div>
                <div>
                  <h1
                    onClick={openModal}
                    className=" text-center   hover:text-secondary-300  font-medium text-white text-sm underline cursor-pointer"
                  >
                    Forgot Password?
                  </h1>
                </div>

                <Button2
                  disabled={loading}
                  // onClick={handleLogin}

                  className={" uppercase font-semibold text-sm"}
                >
                  Log In
                </Button2>
              </form>
            </div>

            <div
              className={`transition duration-300 ease-in-out ${
                select === 2 ? "opacity-100" : "hidden opacity-0"
              }`}
            >
              <form
                onSubmit={handleSubmit}
                action=""
                className=" flex flex-col gap-5 "
              >
                <div className="   flex flex-col   gap-5">
                  <div className="   ">
                    <InputText
                      label={"NAME"}
                      required={true}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      type={"text"}
                      className=""
                      placeholder={"First Name"}

                      // error={errors.name}
                    />
                  </div>

                  <div className="   ">
                    <InputText
                      label={"EMAIL"}
                      required={true}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      type={"email"}
                      className=""
                      placeholder={"Email"}
                      error={errors.email}
                    />
                  </div>
                  <div className="   ">
                    <InputText
                      label={"PASSWORD "}
                      required={true}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      type={"password"}
                      className=""
                      placeholder={"Password"}
                      maxLength={20}
                      showPassword={showPassword}
                      togglePassword={() => setShowPassword(!showPassword)}
                      error={errors.password}
                    />
                  </div>
                </div>

                <div className="  flex  items-start gap-2">
                  <input
                    required
                    type="checkbox"
                    name=""
                    id="check_policy"
                    className="  mt-2"
                  />

                  <label
                    htmlFor="check_policy"
                    className="    cursor-default  font-normal text-xs text-secondary-300"
                  >
                    Tick here to receive emails about our products, apps,{" "}
                    <br className=" hidden  md:flex" /> sales, exclusive content
                    and more. View our{" "}
                    <span className=" text-white cursor-pointer">
                      Privacy Policy
                    </span>
                    .
                  </label>
                </div>
                <Button2
                  disabled={loading}
                  className={" uppercase font-semibold text-sm"}
                >
                  Create
                </Button2>
              </form>
            </div>

            <div className="  flex justify-center">
              <button
                className=" text-white text-sm flex items-center gap-1  "
                onClick={handleGoogleSignIn}
              >
                <FcGoogle size={20} />
                <span> Sign in with Google</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <ForgotModal
        errors={errors}
        loading={loading2}
        handleReset={handleReset}
        email={forgotemail}
        setEmail={setForgotEmail}
        isModalOpen={isModalOpen}
        closeModal={closeModal}
      />
    </Fragment>
  );
};

const LoginMain = ({ option, productId, productName, cart }) => {
  const [select, setSelect] = useState(option ? option : 1);

  const selectOption = (option) => {
    setSelect(option);
  };

  return (
    <Fragment>
      <div className="  bg-secondary-100      h-[100vh]">
        <div className=" xl:grid grid-cols-2">
          <div className=" hidden xl:flex flex-col ">
            <Poster />
          </div>
          <Login
            cart={cart}
            productId={productId}
            productName={productName}
            select={select}
            selectOption={selectOption}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default LoginMain;
