import React, { Fragment, useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation } from "swiper/modules";

import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import Image from "components/Layout/Fields/Image";
import { NavLink } from "react-router-dom";
const CardSwiper = ({ data, title }) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const swiperRef = useRef(null);

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.navigation.init();
      swiperRef.current.navigation.update();
    }
  }, [swiperRef.current]);

  return (
    <div className=" flex  flex-col gap-5 md:gap-10">
      <div className=" flex  flex-row justify-between">
        <h1 className=" text-white font-semibold text-xl  md:text-2xl uppercase">
          {title}
        </h1>
        <div className="flex-row  hidden md:flex gap-3">
          <button
            ref={prevRef}
            className="swiper-button-prev p-1 rounded-full bg-white hover:bg-gray-300 disabled:bg-gray-200 disabled:cursor-not-allowed"
          >
            <MdOutlineKeyboardArrowLeft size={25} />
          </button>
          <button
            ref={nextRef}
            className="swiper-button-next p-1 rounded-full cursor-pointer bg-white hover:bg-gray-300 disabled:bg-gray-200 disabled:cursor-not-allowed"
          >
            <MdKeyboardArrowRight size={25} />
          </button>
        </div>
      </div>

      <Swiper
        onInit={(swiper) => {
          swiperRef.current = swiper;
          swiper.params.navigation.prevEl = prevRef.current;
          swiper.params.navigation.nextEl = nextRef.current;
        }}
        slidesPerView={4}
        breakpoints={{
          200: { slidesPerView: 1.2 },
          640: { slidesPerView: 2 },
          768: { slidesPerView: 2 },
          1024: { slidesPerView: 3 },
          1280: { slidesPerView: 4 },
        }}
        modules={[Navigation]}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        spaceBetween={10}
        className="mySwiper"
      >
        {data.map((item, index) => (
          <SwiperSlide key={index}>
            <div  state={item.title}  className="  cursor-pointer flex flex-col gap-2   ">
              <div className="h-[450px] overflow-hidden rounded">
                <Image className={""} src={item.image} alt="" />

                {/* <img
                  className=" w-full object-cover  h-full"
                  src={item.image}
                  alt=""
                /> */}
              </div>
              <div>
                {/* <div className="  flex px-2  justify-between">
                  <div className=" flex flex-col gap-1">
                    <h1 className=" text-white  font-medium text-sm">
                      {item.title}
                    </h1>
                    <h1 className=" text-xs   text-secondary-300  font-light">
                      {item.title2}
                    </h1>
                  </div>
                  <h1 className="  text-primary-100 font-medium text-xs">
                    <Currency quantity={item.price} currency="inr" />
                  </h1>
                </div> */}
              </div>
            </div>
          </SwiperSlide>
 

        ))}
      </Swiper>
    </div>
  );
};

export default CardSwiper;
