import Layout from "components/Layout/Layout";
import PolicyMain from "components/Policy/PolicyMain";
import React from "react";

const Faq = () => {
  const data = [
    {
      id: 1,
      title: "To place your order, follow the steps mentioned below:",
      description: `Browse by article of your choice View details of the product, select your size and add to "cart" Enter your shipping address with pin code Please make sure that you check the total amount and products added to your cart before proceeding. Next, select the payment option. (Net-Banking/Debit or Credit) To complete the online payment, you will be redirected to our online payment partner RazorPay. Once your payment is confirmed, your order will be placed successfully. You will receive a confirmation SMS & Email with your order details.`,
    },
    {
      id: 2,
      title: "Can I add item or change size after placing an order?",
      description: `Currently, we do not have an option where a customer can change the size or add a new item to a purchased order. Instead, a new order can be placed and return/cancel the existing order.`,
    },
    {
      id: 3,
      title: "Can I change my delivery address after I have placed an order?",
      description: `The shipping address cannot be changed once the order Is successfully placed.`,
    },
    {
      id: 4,
      title: "What emails can I expect once my order has been placed ?",
      description: `As soon as the order is confirmed, you will receive 3 emails: \n A mail will be sent to your mailing address. \n A mail confirming that the order has been shipped along with a tracking link. \n  A mail confirming that your order has been successfully delivered.`,
    },
    {
      id: 5,
      title: "What taxes will be levied on the transactions ?",
      description: `All our prices are inclusive of sales tax. Sales Tax is calculated based on the GST laws.`,
    },
    {
      id: 6,
      title: "Are there any shipping charges ?",
      description: `No shipping charges. All deliveries are free.`,
    },
    {
      id: 7,
      title: "Do you deliver anywhere in India",
      description: `We deliver to most cities in India. We also ship internationally.`,
    },
    // {
    //   id: 8,
    //   title: "How do I find the product I'm looking for on the APP?",
    //   description: `You can find our products on our App through a simple search. Either enter the product name or article number in the search option on the top of the page. After completing the search, you can add the selected articles you want by clicking on the option ‘Add to Cart`,
    // },
    // {
    //   id: 9,
    //   title: "Do I need to create an account to buy from your Shopping App?",
    //   description: `No, it is not mandatory to create an account in order to shop on onepercentclubaw.com. You can do so as a guest user. However, you will have to share your email ID. However, creating an account can save you time during checkout as it saves your address details.`,
    // },
    {
      id: 10,
      title: "Which payment methods do you accept?",
      description: `We accept the following modes of payment: \n Credit Cards \n Debit Cards \n Internet Banking \n UPI.`,
    },

    // {
    //   id: 12,
    //   title: "Returns and Cancellation",
    //   description: `How do I return my onepercentclubaw.com product(s)? \n Login to your Mobile App \n  Click on view Profile. \n  Navigate to Orders and select the specific order and click on Initiate Return. \n  Select the product and enter the quantity. \n  Enter the reason for return and upload the images of the product** \n  Enter the address for pickup of your order and submit.
    // \n Your return request has been placed!! \n Once the product(s) reaches back our warehouse and completes the quality check, a refund will be initiated within 48 hours back to the mode of payment. \n Visit our Returns and Exchange Policy page for more info
    //         `,
    // },

    // {
    //   id: 13,
    //   title: "How can I cancel my order?",
    //   description: `To cancel an order, call us on the toll-free number +9170033 51046.`,
    // },
    // {
    //   id: 14,
    //   title: "Please Note: ",
    //   description: `In case the order is already dispatched, then we will try our level best to stop the shipment. However, we cannot commit to cancel it. In case the delivery is attempted, you can refuse to accept the order. \n Visit our Returns and Exchange Policy page for more info.`,
    // },
  ];
  return (
    <Layout title={"FAQs"}>
      <PolicyMain title={"FAQs"} data={data} />
    </Layout>
  );
};

export default Faq;
