import { configureStore } from '@reduxjs/toolkit';
import BasketSlice from 'ReduxSlice/BasketSlice';
import { save, load } from 'redux-localstorage-simple';


const preloadedState = load();

export const store = configureStore({
  reducer: {
    basket: BasketSlice,
  },
  preloadedState,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(save()),
});

export default store;