import Button2 from "components/Layout/Fields/Button2";
import React from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";
import { auth } from "Firestore/Firebase";
import toast from "react-hot-toast";
const Detail = ({ openModal, name, email, mainAddress }) => {
  const navigation = useNavigate();
  const getInitials = (name) => {
    const words = name?.split(" ");
    const initials = words
      ?.slice(0, 2)
      ?.map((word) => word[0])
      .join("");
    return initials?.toUpperCase(); // Optional: Convert to uppercase
  };
  const shortName = getInitials(name);

  const logOut = () => {
    auth.signOut();
    navigation("/");
    toast.success("LogOut Successfully", {
      style: {
        background: "rgba(25,25,25)",
        color: "white",
        fontSize: "13px",
        textTransform: "uppercase",
      },
    });
  };

  return (
    <div className=" flex flex-col  gap-8 ">
      <div className=" flex gap-5 items-start">
        <div className=" bg-white text-lg  h-14 items-center flex justify-center    w-14 font-semibold rounded-full">
          <h1>{shortName}</h1>
        </div>
        <div className="  flex flex-col gap-1">
          <h1 className="  tracking-wide   font-semibold text-white uppercase">
            {name}
          </h1>
          <h1 className=" text-xs  font-light tracking-wide  text-secondary-300">
            {email}
          </h1>
          <h1
            onClick={logOut}
            className="  underline text-sm  tracking-wide  font-light  cursor-pointer text-white"
          >
            Log out
          </h1>
        </div>
      </div>
      {mainAddress && (
        <div className=" bg-secondary-300 p-5 flex flex-col gap-3">
          <h1 className=" uppercase tracking-wide    text-secondary-100 font-semibold">
            MAIN ADDRESS
          </h1>
          <div>
            <h1 className=" text-sm  text-secondary-200 tracking-wide">
              {mainAddress?.name} {mainAddress?.lastname}
            </h1>
            <h1 className=" text-sm  text-secondary-200 tracking-wide">
            {mainAddress?.address } <br />
            {mainAddress?.street }
            </h1>
            <h1 className=" text-sm  text-secondary-200 tracking-wide">
            {mainAddress?.pincode } {mainAddress?.city } {mainAddress?.state }
            </h1>
            <h1 className="text-sm  text-secondary-200 tracking-wide">India</h1>
          </div>
        </div>
      )}

      <Button2
        onClick={openModal}
        disabled={false}
        className={
          "  whitespace-pre md:px-5 flex items-center gap-2  py-3 lg:px-10  text-sm  uppercase"
        }
      >
        <AiOutlinePlus size={20} color="black" />
        ADD AN ADDRESS
      </Button2>
    </div>
  );
};

export default Detail;
