import ZoomImage from "components/Layout/Fields/ZoomImage";
import React, { Fragment } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
// import "swiper/css/navigation";
// import "swiper/css/pagination";
import "swiper/css/autoplay";
import {
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
  Autoplay,
} from "swiper/modules";

const CustomDot = ({ onClick, active, img }) => (
  <>
    {img?.length > 1 && (
      <button
        className={` rounded-lg  ${
          active ? " bg-white " : " bg-secondary-200"
        }`}
        style={{
          width: active ? "20px" : "10px",
          height: "5px",
          margin: "0 5px",
          cursor: "pointer",
        }}
        onClick={() => onClick()}
      />
    )}
  </>
);

const ImagesSection = ({ gallery }) => {
  return (
    <Fragment>
      {/* Web */}
      <div className=" hidden md:grid lg:grid-cols-2 gap-5">
        {gallery.map((item, index) => (
          <div key={index} className=" overflow-hidden  h-[550px]  ">
            {/* <Image className={""} src={item.img} alt="" /> */}

            <ZoomImage src={item.image} />
          </div>
        ))}
      </div>
      {/* Mobile */}
      <div>
        <div className=" md:hidden  flex  w-full rounded  overflow-hidden ">
          <Swiper
            // loop={true}
            modules={[Autoplay, Navigation, Pagination, Mousewheel, Keyboard]}
            autoplay={{ delay: 3000 }}
            cssMode={true}
            pagination={{ clickable: true }}
            className="mySwiper"
          >
            {gallery.map((item, index) => (
              <SwiperSlide key={index}>
                <div className="hero-slide relative">
                  <div className="h-[500px] w-full">
                    <img
                      className="w-full h-full object-cover"
                      src={item.image}
                      alt="Default Image"
                    />
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </Fragment>
  );
};

export default ImagesSection;
