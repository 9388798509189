import React from "react";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const ModalSection = ({ children, isOpen, onClose, className ,  padding }) => {
  return (
    <div  className=" z-50 ">
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div
          className={`${className}   w-[400px]   sm:min-w-[500px]  md:min-w-[600px] max-h-[700px]  md:max-h-[900px]  ${padding ? padding:'p-4' } `}
        >
          {children}
        </div>
      </Modal>
    </div>
  );
};

export default ModalSection;
