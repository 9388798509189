import React, { Fragment } from "react";
import ProductsCard from "./ProductsCard";
import Summery from "./Summery";
import ProductSwiper from "components/Cards/ProductSwiper";
import Secure from "./Secure";
import { selectBasketCount, selectBasketItems } from "ReduxSlice/BasketSlice";
import { useSelector } from "react-redux";
import Image from "components/Layout/Fields/Image";
import Button2 from "components/Layout/Fields/Button2";
import Button3 from "components/Layout/Fields/Button3";
import { useNavigate } from "react-router-dom";
import image from "assets/images/EmptyCart.png"
const EmptyCart = ({ navigate }) => {
  const naviagtion = useNavigate();

  return (
    <div className=" py-10 pb-20">
      <div className=" flex flex-col justify-center items-center    gap-5 ">
        <div className=" w-44">
          <Image src={image} />
        </div>
        <div className="  flex flex-col  text-center gap-1">
          <h1 className="text-lg text-white  font-semibold tracking-wide">
          YOUR CART IS EMPTY

          </h1>
          <p className=" text-secondary-300 text-xs">
          There are no products in your bag.
          </p>
        </div>

        <div className=" flex flex-col gap-2  w-64">
          <Button2
            onClick={() => naviagtion("/shop-men")}
            className={" uppercase  font-medium"}
          >
            Shop Mens
          </Button2>
          <Button3
            onClick={() => naviagtion("/shop-women")}
            disabled={false}
            className={" border"}
          >
            Shop Women
          </Button3>
        </div>
      </div>
    </div>
  );
};

const CartMain = ({user}) => {


  const cartData = useSelector(selectBasketItems);

  const cartCount = useSelector(selectBasketCount);

  return (
    <div className="bg-secondary-100  flex  flex-col gap-10  ">
      <div className=" py-5 md:py-10 pb-20  ">
        <div className=" container   px-5  2xl:px-24  ">
          <div className=" flex flex-col gap-10">
            <div>

            <h1 className=" text-white text-xl md:text-lg  font-semibold tracking-wider">
              MY SHOPPING CART <span className=" text-secondary-300">({cartCount})</span>
            </h1>
            {/* <p className=" text-xs text-secondary-300 uppercase">
            Items in the cart will be automatically removed after 24 hours.
            </p> */}
            </div>
            {cartData.length > 0 ? (
              <Fragment>
                <div className="   flex flex-col xl:gap-0  gap-5 lg:flex-row  justify-around">
                  <div className=" xl:w-[50%] flex  flex-col gap-2 ">
                    {cartData.map((item, index) => (
                      <ProductsCard index={index} item={item} key={index} />
                    ))}
                  </div>
                  <div className="   xl:w-[45%] ">
                    <Summery user={user} />
                  </div>
                </div>
                <div className=" py-10 md:py-40">
                  <Secure />
                </div>
              </Fragment>
            ) : (
              <EmptyCart />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartMain;
