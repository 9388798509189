import HomeMain from "components/Home/HomeMain";
import Layout from "components/Layout/Layout";
import { db } from "Firestore/Firebase";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { doc, getDoc } from "firebase/firestore";

const Home = () => {

  const [video, setVideo] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const docRef = doc(db, "video", "video");
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setVideo(docSnap.data().video);
          setLoading(false);
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };

    fetchData();
  }, []);

  const videoSrc = video;

  return (
    <Layout title={"Home"} loading={loading}>
      <HomeMain videoSrc={videoSrc} />
    </Layout>
  );
};

export default Home;
