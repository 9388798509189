import { FaCcMastercard, FaCcVisa, FaLinkedin } from "react-icons/fa6";
import { LuInstagram } from "react-icons/lu";
import { FaFacebook } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";
import Visa from "assets/images/Icons/visa.png";
import Master from "assets/images/Icons/master.png";

const FooterData = {
  year: new Date().getFullYear(),
  author: "One Percent Club AW",
  support: [
    { id: 1, title: "Contact us", path: "/contact-us" },
    // { id: 2, title: "Promotions & Sale", path: "" },
    // { id: 3, title: "Track Order", path: "" },
  ],
  terms: [
    { id: 1, title: "FAQs", path: "/faq" },
    { id: 2, title: "Exchange & Return Policy", path: "/return-policy" },
    { id: 3, title: "Privacy Policy", path: "/privacy-policy" },
    { id: 4, title: "Terms & Conditions", path: "/terms-and-conditions" },
  ],
  ourstory: [{ id: 1, title: "Explore More", path: "" }],
  social: [
    { id: 1, Icon: LuInstagram, path: "https://www.instagram.com/one1percentclub_?igsh=MXhhMHdkNHd4NnE5bw%3D%3D&utm_source=qr" },
    // { id: 2, Icon: FaFacebook, path: "" },
    // { id: 3, Icon: FaXTwitter, path: "" },
    // { id: 4, Icon: FaYoutube, path: "" },
    {
      id: 5,
      Icon: FaLinkedin,
      path: "https://www.linkedin.com/in/one-percent-club-activewear-09ba77319?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    },
  ],
  payAccept: [
    { id: 1, img: Visa },
    { id: 2, img: Master },
    // { id: 3, img: Paypal },
  ],
};

export default FooterData;
