import { Input } from "@material-tailwind/react";
import React, { Fragment, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const InputTextArea = ({
  className,
  placeholder,
  required,
  type,
  showPassword,
  togglePassword,
  error,
  label,
  color,
  ...props
}) => {
  return (
    <Fragment>
      <div className="relative   w-full">
        <div className="w-full  flex   flex-col gap-2">
          {label && (
            <label
              htmlFor={label}
              className={` font-semibold text-xs ${color ? color : " text-white"} `}
            >
              {label}{" "}
              {required && <span className="text-secondary-300">*</span>}
            </label>
          )}

          <div>
            <textarea
            maxLength={200}
              id={label}
              placeholder={placeholder}
              required={required}
              {...props}
              type={showPassword ? "text" : type}
              className={`relative   border   font-light p-3 bg-transparent ${
                color ? color : " text-white"
              } focus:outline-0 text-sm rounded border-secondary-200 w-full  
              border-neutral-80 focus-within:border-neutral-100   ring-gray-500 focus-within:ring
              ${
                color
                  ? " focus:border-secondary-200 "
                  : "  focus:border-white"
              }
               
     
     ${className}`}
            ></textarea>
          </div>
        </div>
   
        {error && (
          <p className=" text-secondary-300 text-xs  font-extralight mt-3 uppercase">
            {error}
          </p>
        )}
      </div>
    </Fragment>
  );
};

export default InputTextArea;
