import React, { Fragment, useState } from "react";

import { MdEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";

const Address = ({
  addressList,
  userDetails,
  updateMainAddress,
  deleteAddress,
  mainAddress,
  editAddress,
}) => {
  const deleteUpdate = (id) => {
    deleteAddress(id);
    if (addressList.length > 1 && mainAddress.id === id) {
      if (addressList[0].id === id) {
        updateMainAddress(addressList[1].id,addressList[1]?.phone);
      } else {
        updateMainAddress(addressList[0].id,addressList[0]?.phone);
      }
    }
  };

  return (
    <Fragment>
      <div className="  flex flex-col gap-5">
        <div>
          <h1 className="  tracking-wide  text-xl  font-semibold text-white uppercase">
            YOUR ADDRESSES
          </h1>
        </div>
        {addressList?.length > 0 ? (
          <Fragment>
            {addressList?.map((item, index) => (
              <div key={index} className=" border  border-secondary-200 p-6">
                <div className=" flex flex-col gap-5 md:gap-0  md:flex-row justify-between">
                  <div>
                    <address>
                      <p className=" text-secondary-300 text-sm  font-light">
                        {item?.name} {item?.lastname} <br />
                        {item?.address} <br />
                        {item?.street} <br />
                        {item?.pincode} {item?.city} {item?.state} <br />
                        India
                      </p>
                    </address>
                  </div>
                  <div className=" flex md:flex-col justify-between md:gap-2  items-end ">
                    <button
                      onClick={() => editAddress(item)}
                      className=" text-white   flex items-center  gap-2"
                    >
                      <MdEdit size={16} />
                      <span className=" underline   font-medium text-sm ">
                        {" "}
                        Edit
                      </span>
                    </button>
                    <button
                      onClick={() => deleteUpdate(item.id)}
                      className=" text-white   flex items-center  gap-2"
                    >
                      <RiDeleteBin6Line size={16} />
                      <span className=" underline   font-medium text-sm ">
                        {" "}
                        Delete
                      </span>
                    </button>
                    <div
                      // onClick={
                      //   addressList.length === 1 && mainAddress?.id !== item.id
                      //     ? updateMainAddress(item.id)
                      //     : () => updateMainAddress(item.id)
                      // }

                      onClick={() =>
                        userDetails?.mainaddress === item.id
                          ? null
                          : updateMainAddress(item.id, item.phone)
                      }
                      className={`  
                    ${
                      userDetails?.mainaddress === item.id
                        ? ""
                        : "cursor-pointer"
                    }
                    flex gap-2 items-center  `}
                    >
                      <div
                        className={`
                        ${
                          userDetails?.mainaddress === item.id
                            ? " border-white "
                            : " border-secondary-200 border-2  "
                        }
                    p-1 border  rounded-full cursor-pointer `}
                      >
                        <div
                          className={` ${
                            userDetails?.mainaddress === item.id
                              ? "bg-white "
                              : "  "
                          }
                          p-1.5 rounded-full `}
                        ></div>
                      </div>
                      <p className="    text-secondary-300 text-sm font-light">
                        {userDetails?.mainaddress === item.id
                          ? "Main Address"
                          : " Make this my main"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Fragment>
        ) : (
          <div>
            <h1 className=" text-white text-sm   tracking-wide  font-light">
              ADDRESS BOOK IS EMPTY
            </h1>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default Address;
