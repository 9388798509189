import Image from "components/Layout/Fields/Image";
import Select from "components/Layout/Fields/Select";
import React, { useState } from "react";
import Currency from "react-currency-formatter";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
const ProductsCard = ({ item }) => {
  const [selected, setSelected] = useState(1);
  const naviagtion = useNavigate();

  const handleProductClick = (item) => {
    naviagtion(`/products/${item.category}/${item.id}`);
  };

  return (
    <div className="  border border-secondary-200 p-5 flex   gap-5 flex-col  flex-wrap  justify-between">
      <div className="   flex   gap-3 md:justify-start  md:gap-5">
        <div
          onClick={() => handleProductClick(item)}
          className=" w-[90px]  cursor-pointer   h-24"
        >
          <Image src={item.image} />
        </div>
        <div className="  md:w-auto  w-[calc(100%-130px)]  flex flex-col gap-1 ">
          <div>
            <h1
              onClick={() => handleProductClick(item)}
              className="  text-white cursor-pointer  tracking-wider  text-base "
            >
              {item.name}
            </h1>
            <h1 className=" text-xs text-secondary-300  ">{item.subtitle}</h1>
          </div>

          <div className=" flex flex-col gap-0">
            {item.color && (
              <h1 className="  text-white   tracking-wider    font-extralight  text-xs">
                Color :{" "}
                <span className=" text-secondary-300 font-light capitalize">
                  {item.color}
                </span>
              </h1>
            )}

            {item.size && (
              <h1 className="  text-white   tracking-wider    font-extralight  text-xs">
                Size :{" "}
                <span className=" text-secondary-300 uppercase font-light">
                  {item.size}
                </span>
              </h1>
            )}
          </div>
        </div>
      </div>

      <div className=" flex justify-between   w-full gap-3">
        <div className=" flex flex-row justify-between  w-full gap-2 ">
          <h1 className="  text-white   tracking-wider    font-medium text-xs">
            Quantity :{" "}
            <span className=" text-secondary-300 font-medium capitalize">
              {" "}
              {item.quantity}
            </span>
          </h1>
          <h1 className="  text-primary-100 font-medium  text-sm">
            <Currency quantity={item.price * item.quantity} currency="inr" />
          </h1>
        </div>
      </div>
    </div>
  );
};

export default ProductsCard;
