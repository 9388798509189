import { auth } from "Firestore/Firebase";
import CartMain from "components/Cart/CartMain";
import Layout from "components/Layout/Layout";
import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
const ProductView = () => {

  const [user] = useAuthState(auth);

  return (
    <Layout title={"Cart"}>
      <CartMain user={user} />
    </Layout>
  );
};

export default ProductView;
