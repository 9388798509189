import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Address from "./Address";
import Summery from "./Summery";
import toast from "react-hot-toast";
import Logo from "assets/images/darklogo.png";
import { useDispatch, useSelector } from "react-redux";
import {
  removeAllItems,
  selectBasketCount,
  selectBasketItems,
  selectBasketTotal,
} from "ReduxSlice/BasketSlice";
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "Firestore/Firebase";
import emailjs from "@emailjs/browser";
import { format } from "date-fns";
import axios from "axios";
import { BASE_URL, razorpay_key } from "../../config";

const Tabs = () => {
  return (
    <div>
      <div className=" flex gap-4">
        <NavLink to={"/cart"}>
          <h1 className=" text-sm tracking-wider cursor-pointer uppercase  text-secondary-300 border-b   ">
            cart
          </h1>
        </NavLink>
        <NavLink to={"/checkout"}>
          <h1 className=" text-sm tracking-wider  cursor-text uppercase  text-white border-b   ">
            payment
          </h1>
        </NavLink>
      </div>
    </div>
  );
};

const CheckoutMain = ({
  total,
  discount,
  userDetails,
  mainAddress,
  coupon,
  addressList,
  user,
  loading2,
  setLoading2,
}) => {
  const token = user?.accessToken;
  const cartData = useSelector(selectBasketItems);
  const basketTotal = useSelector(selectBasketTotal);
  const cartCount = useSelector(selectBasketCount);

  const naviagtion = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    if (loading2 !== true && cartData.length === 0) {
      naviagtion("/");
    }
  }, [cartData]);

  const [order, setOrder] = useState(null);
  const [error, setError] = useState(null);

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;

      script.onload = () => {
        resolve(true);
      };

      script.onerror = () => {
        resolve(false);
      };

      document.body.appendChild(script);
    });
  };

  const date = new Date();
  const formattedDate = format(date, "MMM dd yyyy hh:mm a");

  const [addressEmail, setAddressEmail] = useState();
  const [addressNumber, setAddressNumber] = useState();

  const [orderId, setOrderid] = useState(null);

  const [load, setLoad] = useState(false);

  const createOrder = async () => {
    setLoad(true); // Set loading state to true

    const data = {
      cartItems: [cartData],
      cartPrice: total,
    };

    try {
      const response = await axios.post(`${BASE_URL}/order/buy-now`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const orderid = response.data.data.data.razorPayOrderId;
      if (orderid) {
        setLoad(false); // Set loading state to false
        checkOut(orderid); // Proceed to checkout with the order ID
        setOrderid(orderid); // Set the order ID in the state
      } else {
        throw new Error("Order ID not found in response");
      }
    } catch (error) {
      console.error("Error placing order:", error);
      setLoad(false); // Ensure loading state is set to false in case of an error
      // Optionally, you could set an error state here to show a user-friendly message
    }
  };

  const checkOut = async (order_id) => {
    if (!addressEmail || !addressNumber) {
      toast.error("ENTER CONTACT INFO", {
        style: {
          background: "rgba(25,25,25)",
          color: "white",
          fontSize: "13px",
          textTransform: "uppercase",
        },
      });
      return;
    }

    try {
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );

      if (!res) {
        throw new Error("Failed to load Razorpay SDK");
      }

      const options = {
        key: razorpay_key,
        order_id: order_id,
        image: Logo,
        currency: "INR",
        amount: total * 100,
        name: "One Percent Club Aw",
        description: "Thanks for purchasing",
        theme: {
          color: "rgba(25,25,25)",
        },
        handler: async (response) => {
          // razorpay_signature
          setLoading2(true);
          try {
            if (response.razorpay_payment_id) {
              toast.success("Payment Successfully", {
                style: {
                  background: "rgba(25,25,25)",
                  color: "white",
                  fontSize: "13px",
                  textTransform: "uppercase",
                },
              });

              const docRef = await addDoc(collection(db, "orders"), {
                name: userDetails.name || "",
                email: userDetails.email || "",
                phone: userDetails.phone || "",
                uid: user?.uid || "",
                quantity: cartCount || "",
                subtotal: basketTotal || "",
                discount: discount || "",
                coupon: coupon || "",
                total: total || "",
                razorpay_payment_id: response.razorpay_payment_id || "",
                razorpay_order_id: order_id || "",
                razorpay_signature: response.razorpay_signature || "",
                status: "confirmed",
                date: serverTimestamp(),
                orderdate: formattedDate,
              });

              await updateDoc(doc(db, "orders", docRef.id), {
                orderid: docRef.id,
              });

              await addDoc(collection(db, "orders", docRef.id, "address"), {
                address: mainAddress.address || "",
                city: mainAddress.city || "",
                email: mainAddress.email || "",
                firstname: mainAddress.firstname || "",
                lastname: mainAddress.lastname || "",
                name: mainAddress.name || "",
                phone: mainAddress.phone || "",
                pincode: mainAddress.pincode || "",
                state: mainAddress.state || "",
                street: mainAddress.street || "",
                deliveryemail: addressEmail || "",
                deliverycontact: addressNumber || "",
              });

              const docRef2 = doc(
                collection(db, "users", user?.uid, "orders"),
                docRef.id
              );

              await setDoc(docRef2, {
                name: userDetails.name || "",
                razorpay_payment_id: response.razorpay_payment_id || "",
                razorpay_signature: response.razorpay_signature || "",
                razorpay_order_id: order_id || "",
                email: userDetails.email || "",
                phone: userDetails.phone || "",
                quantity: cartCount || "",
                subtotal: basketTotal || "",
                discount: discount || "",
                coupon: coupon || "",
                total: total || "",
                status: "confirmed",
                orderid: docRef.id || "",
                date: serverTimestamp() || "",
                orderdate: formattedDate,
              });

              await addDoc(
                collection(
                  db,
                  "users",
                  user?.uid,
                  "orders",
                  docRef.id,
                  "address"
                ),
                {
                  address: mainAddress.address || "",
                  city: mainAddress.city || "",
                  email: mainAddress.email || "",
                  firstname: mainAddress.firstname || "",
                  lastname: mainAddress.lastname || "",
                  name: mainAddress.name || "",
                  phone: mainAddress.phone || "",
                  pincode: mainAddress.pincode || "",
                  state: mainAddress.state || "",
                  street: mainAddress.street || "",
                  deliveryemail: addressEmail || "",
                  deliverycontact: addressNumber || "",
                }
              );

              for (let i = 0; i < cartData.length; i++) {
                await addDoc(collection(db, "orders", docRef.id, "products"), {
                  name: cartData[i]?.name || "",
                  productid: cartData[i]?.id || "",
                  subtitle: cartData[i]?.subtitle || "",
                  price: cartData[i]?.price || "",
                  color: cartData[i]?.color || "",
                  size: cartData[i]?.size || "",
                  category: cartData[i]?.category || "",
                  image: cartData[i]?.image || "",
                  quantity: cartData[i]?.quantity || "",
                });

                await addDoc(
                  collection(
                    db,
                    "users",
                    user?.uid,
                    "orders",
                    docRef.id,
                    "products"
                  ),
                  {
                    name: cartData[i]?.name || "",
                    productid: cartData[i]?.id || "",
                    subtitle: cartData[i]?.subtitle || "",
                    price: cartData[i]?.price || "",
                    color: cartData[i]?.color || "",
                    size: cartData[i]?.size || "",
                    category: cartData[i]?.category || "",
                    image: cartData[i]?.image || "",
                    quantity: cartData[i]?.quantity || "",
                  }
                );
              }

              const data = {
                orderid: docRef.id,
                razorpay_order_id: "",
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_order_id: "",
                quantity: cartCount || "",
                subtotal: basketTotal || "",
                coupon: coupon?.name || "N/A",
                coupondiscount: coupon?.discount || "N/A",
                discount: discount || "N/A",
                date: formattedDate || "",
                total: total || "",
                address: mainAddress.address || "",
                city: mainAddress.city || "",
                firstname: mainAddress.firstname || "",
                lastname: mainAddress.lastname || "",
                name: mainAddress.name || "",
                pincode: mainAddress.pincode || "",
                state: mainAddress.state || "",
                street: mainAddress.street || "",
                deliveryemail: addressEmail || "",
                deliverycontact: addressNumber || "",
              };

              await emailjs.send(
                "service_0ureci6",
                "template_t7yy6z9",
                data,
                "VLVKkxM5qiYxKD0hn"
              );

              toast.success("Order Placed Successfully", {
                style: {
                  background: "rgba(25,25,25)",
                  color: "white",
                  fontSize: "13px",
                  textTransform: "uppercase",
                },
              });
              setLoad(false);

              try {
                const response = await axios.post(
                  `${BASE_URL}/order/check-status`,
                  { orderId: docRef.id },
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                );
                // console.log(response);
              } catch (error) {
                console.error("Error placing order:", error);
              }

              setTimeout(() => {
                dispatch(removeAllItems());
                naviagtion("/account");
              }, 2000);
            } else {
              setLoad(false);
              toast.error("Payment Failed", {
                style: {
                  background: "rgba(25,25,25)",
                  color: "white",
                  fontSize: "13px",
                  textTransform: "uppercase",
                },
              });
            }
          } catch (error) {
            setLoad(false);
            toast.error(error.message, {
              style: {
                background: "rgba(25,25,25)",
                color: "white",
                fontSize: "13px",
                textTransform: "uppercase",
              },
            });
          }
        },

        prefill: {
          name: userDetails.name,
          email: userDetails.email,
          contact: userDetails.phone,
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      toast.error(error.message, {
        style: {
          background: "rgba(25,25,25)",
          color: "white",
          fontSize: "13px",
          textTransform: "uppercase",
        },
      });
    }
  };

  return (
    <div className="bg-secondary-100  flex  flex-col gap-10  ">
      <div className=" py-5  pb-20  ">
        <div className=" container   px-5    2xl:px-40 ">
          <div className=" flex flex-col  gap-5">
            <div>
              <h1 className=" text-white text-xl md:text-2xl  font-semibold tracking-wider">
                CHECKOUT
              </h1>
            </div>

            <div>
              <Tabs />
            </div>

            <div className="  py-5   gap-10 lg:gap-0   flex-col-reverse lg:flex-row  flex justify-between">
              <div className=" lg:w-[58%]">
                <Address
                  load={load}
                  addressEmail={addressEmail}
                  addressNumber={addressNumber}
                  setAddressEmail={setAddressEmail}
                  setAddressNumber={setAddressNumber}
                  user={user}
                  addressList={addressList}
                  createOrder={createOrder}
                  userDetails={userDetails}
                  mainAddress={mainAddress}
                />
              </div>
              <div className=" lg:w-[40%]">
                <Summery
                  mainAddress={mainAddress}
                  basketTotal={basketTotal}
                  cartData={cartData}
                  total={total}
                  discount={discount}
                  coupon={coupon}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutMain;
