// import { createSlice } from "@reduxjs/toolkit";

// const initialState = {
//   items: [],
// };

// export const BasketSlice = createSlice({
//   name: "basket",
//   initialState,
//   reducers: {
//     addToBasket: (state, action) => {
//       const { id, size } = action.payload;
//       const existingItem = state.items.find(
//         (item) => item.id === id && item.size === size
//       );

//       if (existingItem) {
//         existingItem.quantity += 1;
//       } else {
//         state.items = [...state.items, action.payload];
//       }
//     },
//     removeFromBasket: (state, action) => {
//       const { index } = action.payload;
//       let newBasket = [...state.items];

//       if (index >= 0 && index < newBasket.length) {
//         newBasket.splice(index, 1);
//       } else {
//         console.warn(`Can't remove product at index ${index} as it's out of bounds.`);
//       }

//       state.items = newBasket;
//     },
//     removeAllItems: (state) => {
//       state.items = [];
//     },
//     updateItemQuantity: (state, action) => {
//       const { id, size, quantity } = action.payload;
//       const existingItem = state.items.find(
//         (item) => item.id === id && item.size === size
//       );

//       if (existingItem) {
//         existingItem.quantity = quantity;
//       }
//     },
//   },
// });

// export const { addToBasket, removeFromBasket, removeAllItems, updateItemQuantity } = BasketSlice.actions;

// export const selectBasketItems = (state) => state.basket.items;

// export const selectBasketItemsWithId = (state, id) =>
//   state.basket.items.filter((item) => item.id === id);

// export const selectBasketTotal = (state) =>
//   state.basket.items.reduce((total, item) => total + item.price * item.quantity, 0);

// export default BasketSlice.reducer;




import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
};

export const BasketSlice = createSlice({
  name: "basket",
  initialState,
  reducers: {
    addToBasket: (state, action) => {
      const { id, size } = action.payload;
      const existingItem = state.items.find(
        (item) => item.id === id && item.size === size
      );

      if (existingItem) {
        existingItem.quantity += 1;
      } else {
        state.items = [...state.items, { ...action.payload, quantity: 1 }];
      }
    },
    removeFromBasket: (state, action) => {
      const { index } = action.payload;
      let newBasket = [...state.items];

      if (index >= 0 && index < newBasket.length) {
        newBasket.splice(index, 1);
      } else {
        console.warn(`Can't remove product at index ${index} as it's out of bounds.`);
      }

      state.items = newBasket;
    },
    removeAllItems: (state) => {
      state.items = [];
    },
    updateItemQuantity: (state, action) => {
      const { id, size, quantity } = action.payload;
      const existingItem = state.items.find(
        (item) => item.id === id && item.size === size
      );

      if (existingItem) {
        existingItem.quantity = quantity;
      }
    },
  },
});

export const { addToBasket, removeFromBasket, removeAllItems, updateItemQuantity } = BasketSlice.actions;

export const selectBasketItems = (state) => state.basket.items;

export const selectBasketItemsWithId = (state, id) =>
  state.basket.items.filter((item) => item.id === id);

export const selectBasketTotal = (state) =>
  state.basket.items.reduce((total, item) => total + item.price * item.quantity, 0);

export const selectBasketCount = (state) =>
  state.basket.items.reduce((count, item) => count + item.quantity, 0);

export default BasketSlice.reducer;
