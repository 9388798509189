import React, { useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
const Image = ({ src, className, alt, ...props }) => {
  const [loaded, setLoaded] = useState(false);
  function onLoad() {
    setLoaded(true);
  }

  return (
    <>
      <img
        className={` ${className} w-full h-full object-cover ${
          loaded ? "" : " hidden "
        } `}
        onLoad={onLoad}
        src={src}
        alt={alt}
        {...props}
      />

      {!loaded && (
        <SkeletonTheme baseColor="#202020" highlightColor="#444">
          <Skeleton className=" h-full w-full" />
        </SkeletonTheme>
      )}
    </>
  );
};

export default Image;
