import { auth, db } from "Firestore/Firebase";
import Layout from "components/Layout/Layout";
import WishlistMain from "components/Wishlist/WishlistMain";
import {
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import toast from "react-hot-toast";

const Wishlist = () => {
  const [user] = useAuthState(auth);
  const [loading, setLoading] = useState(true);
  const [wishlist, setWishlist] = useState(null);
  
  const [data, setData] = useState([]);


useEffect(() => {
  if (user) {
    try {
      const menQuery = query(collection(db, "products", "men", "products"));
      const womenQuery = query(collection(db, "products", "women", "products"));
      const accessoriesQuery = query(collection(db, "products", "accessories", "products"));

      Promise.all([
        new Promise((resolve) => {
          onSnapshot(menQuery, (querySnapshot) => {
            const menProducts = [];
            querySnapshot.forEach((doc) => {
              menProducts.push({ id: doc.id, ...doc.data() });
            });
            resolve(menProducts);
          });
        }),
        new Promise((resolve) => {
          onSnapshot(womenQuery, (querySnapshot) => {
            const womenProducts = [];
            querySnapshot.forEach((doc) => {
              womenProducts.push({ id: doc.id, ...doc.data() });
            });
            resolve(womenProducts);
          });
        }),
        new Promise((resolve) => {
          onSnapshot(accessoriesQuery, (querySnapshot) => {
            const accessoriesProducts = [];
            querySnapshot.forEach((doc) => {
              accessoriesProducts.push({ id: doc.id, ...doc.data() });
            });
            resolve(accessoriesProducts);
          });
        })
      ]).then((results) => {
        const combinedProducts = results.flat();
        const filteredProducts = combinedProducts.filter((item) => item.available === true);
        setData(filteredProducts);
        setLoading(false);
      }).catch(() => {
        setLoading(false);
      });

    } catch {
      setLoading(false);
    }
  } else {
    setLoading(false);
  }
}, [user]);

  useEffect(() => {
    if (user) {
      try {
        const q = query(collection(db, "users", user?.uid, "wishlist"));

        onSnapshot(q, (querySnapshot) => {
          const cities = [];
          querySnapshot.forEach((doc) => {
            cities.push({ id: doc.id, ...doc.data() });
          });
          setWishlist(cities);
        });
      } catch {
        console.log("Error fetching data");
      }
    }
  }, [user]);

  const wishlistIds = wishlist?.map((item) => item.id);
  const wishList = data?.filter((item) => wishlistIds?.includes(item.id));
  console.log(wishList.length)

  const removeWishlist = async (id) => {
    try {
      await deleteDoc(doc(db, "users", user?.uid, "wishlist", id));
      toast.success("Removed Successfully", {
        style: {
          background: "rgba(25,25,25)",
          color: "white",
          fontSize: "13px",
          textTransform: "uppercase",
        },
      });
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
  };

  return (
    <Layout loading={loading} title={"Wishlist"}>
      <WishlistMain
        user={user}
        removeWishlist={removeWishlist}
        data={wishList}
      />
    </Layout>
  );
};

export default Wishlist;
