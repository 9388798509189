import React, { useEffect, useState } from "react";
import ProductsCard from "./ProductCard";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import Currency from "react-currency-formatter";
import { BASE_URL } from "../../config";
import { format, parseISO } from "date-fns";
import { TbTruckDelivery } from "react-icons/tb";
import axios from "axios";

const Summery = ({
  total,
  discount,
  coupon,
  cartData,
  basketTotal,
  mainAddress,
}) => {
  const [show, setShow] = useState(true);

  const Icon = show ? IoIosArrowUp : IoIosArrowDown;

  const [date, setDate] = useState(null);

  const apiEndpoint = `${BASE_URL}/order/check-availaibilty?deliveryPincode=${mainAddress?.pincode}&quantity=1`;
  const formattedDate = date && format(parseISO(date), "EEE, MMM d");

  const handleEstCheck = async (e) => {
    try {
      const response = await axios.get(apiEndpoint);
      setDate(response.data.data.data.deliveryDate);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (mainAddress?.pincode) {
      handleEstCheck();
    }
  }, [mainAddress]);

  return (
    <div className=" flex flex-col gap-8">
      <div className="flex flex-col gap-4">
        <div
          onClick={() => setShow(!show)}
          className=" bg-secondary-300 p-4 flex justify-between items-center cursor-pointer"
        >
          <h1 className=" uppercase text-secondary-100  text-base">
            Order details ({cartData.length})
          </h1>

          <Icon className=" text-secondary-100" size={20} />
        </div>
        {show && (
          <div className=" flex flex-col gap-2">
            {cartData.map((item, index) => (
              <ProductsCard item={item} key={index} />
            ))}
          </div>
        )}
      </div>

      <div>
        <div className=" flex   flex-col justify-start gap-2 ">
          {date && (
            <div className=" flex flex-row gap-1">
              <TbTruckDelivery size={17} color="#0085ca" />
              <h1 className=" text-primary-100 text-xs    uppercase font-medium">
              Est Delivery  {formattedDate}
              </h1>
            </div>
          )}
          <h1 className=" text-secondary-300 text-xs  md:text-xs font-medium uppercase tracking-wider">
            Free delivery on all qualifying orders.
          </h1>
        </div>
        <div className=" border-y py-4  my-3 border-secondary-200 flex flex-col gap-2">
          <div className=" flex justify-between items-center">
            <h1 className="   text-xs font-medium text-secondary-300 tracking-wider">
              SUBTOTAL
            </h1>
            <h1 className=" text-xs font-medium   text-white tracking-wider">
              <Currency quantity={basketTotal} currency="inr" />
            </h1>
          </div>
          {coupon && (
            <div className=" flex justify-between items-center">
              <h1 className="   text-xs font-medium text-secondary-300 tracking-wider">
                COUPON DISCOUNT {coupon?.discount}%{" "}
                <span className="  text-primary-100">({coupon?.name})</span>
              </h1>
              <h1 className=" text-xs font-medium   text-white tracking-wider">
                - <Currency quantity={discount} currency="inr" />
              </h1>
            </div>
          )}
        </div>
        <div className=" flex justify-between items-center gap-4 flex-wrap">
          <h1 className="   text-base font-medium text-secondary-300 tracking-wider">
            GRAND TOTAL{" "}
            <span className=" text-xs  text-primary-100">
              {" "}
              PRICES INCLUDE GST
            </span>
          </h1>
          <h1 className=" text-base font-medium   text-white tracking-wider">
            <Currency quantity={total} currency="inr" />
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Summery;
