import React, { Fragment, useEffect, useState } from "react";
import ImagesSection from "./ImagesSection";
import ProductDetail from "./ProductDetail";
import ProductSwiper from "components/Cards/ProductSwiper";
import ProductPoster from "components/Products/ProductPoster";
import BeforeModal from "components/Wishlist/BeforeModal";
import {
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  query,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { db } from "Firestore/Firebase";
import toast from "react-hot-toast";

const ViewMain = ({
  product,
  gallery,
  size,
  related,
  colors,
  user,
  poster,
}) => {



  const button1 = {
    title: "Shop Lifting",
    path: "",
  };
  const button2 = {
    title: "Find out more",
    path: "",
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [select, setSelect] = useState(null);
  const openModal = (item) => {
    setIsModalOpen(!isModalOpen);
    setSelect(item);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setSelect(null);
  };
  const [wishlist, setWishlist] = useState(null);

  useEffect(() => {
    try {
      const q = query(collection(db, "users", user?.uid, "wishlist"));

      onSnapshot(q, (querySnapshot) => {
        const cities = [];
        querySnapshot.forEach((doc) => {
          cities.push({ id: doc.id, ...doc.data() });
        });
        setWishlist(cities);
      });
    } catch {
      console.log("Error fetching data");
    }
  }, [user]);

  const addWishlist = async (item) => {
    try {
      const docRef = doc(
        collection(db, "users", user?.uid, "wishlist"),
        item.id
      );

      await setDoc(docRef, {
        id: item.id,
        name: item.name,
        createdAt: serverTimestamp(),
      });

      toast.success("Added Successfully", {
        style: {
          background: "rgba(25,25,25)",
          color: "white",
          fontSize: "13px",
          textTransform: "uppercase",
        },
      });
    } catch (error) {
      console.error("Error adding document: ", error);
      toast.error(error.message, {
        style: {
          background: "rgba(25,25,25)",
          color: "white",
          fontSize: "13px",
          textTransform: "uppercase",
        },
      });
    }
  };
  const removeWishlist = async (id) => {
    try {
      await deleteDoc(doc(db, "users", user?.uid, "wishlist", id));
      toast.success("Removed Successfully", {
        style: {
          background: "rgba(25,25,25)",
          color: "white",
          fontSize: "13px",
          textTransform: "uppercase",
        },
      });
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
  };

  return (
    <Fragment>
      <div className="bg-secondary-100  flex  flex-col gap-10  ">
        <div className=" md:py-10">
          <div className=" container py-5 md:py-0   px-5 lg:px-16 flex flex-col  gap-10 md:gap-20 ">
            <div className="  flex   flex-col md:flex-row justify-around gap-5">
              <div className="  md:w-[50%]  lg:w-[65%] ">
                <ImagesSection gallery={gallery} />
              </div>
              <div className="  w-full md:w-[50%] lg:w-[30%]  sticky top-20 h-full  ">
                <ProductDetail
                  removeWishlist={removeWishlist}
                  user={user}
                  wishlist={wishlist}
                  openModal={openModal}
                  product={product}
                  size={size}
                  colors={colors}
                />
              </div>
            </div>
            {related.length > 0 && (
              <div>
                <ProductSwiper
                  removeWishlist={removeWishlist}
                  addWishlist={addWishlist}
                  wishlist={wishlist}
                  user={user}
                  openModal={openModal}
                  title={"YOU MAY ALSO LIKE"}
                  data={related}
                />
              </div>
            )}
          </div>
          <div className=" pt-20 pb-10 ">
            {poster && (
              <ProductPoster
                className={`h-[400px] md:h-[600px]`}
                poster={poster}
                button1={button1}
                button2={button2}
              />
            )}
          </div>
        </div>
      </div>
      <BeforeModal
        select={select}
        isModalOpen={isModalOpen}
        closeModal={closeModal}
      />
    </Fragment>
  );
};

export default ViewMain;
